<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable :retain-focus="false"><v-card>
		<v-card-title style="border-bottom:1px solid #999"><b>Edit {{file_title}}</b></v-card-title>
		<v-card-text style="font-size:14px; color:#000;" class="mt-6">
			<froala id="froala_file_editor" :config="editor_config()" v-model="html"></froala>
		</v-card-text>

		<v-card-actions class="mt-3" style="border-top:1px solid #999">
			<v-spacer/>
			<v-btn small class="mr-2" color="secondary" @click="$emit('cancel')" tabindex="6"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
			<v-btn small color="primary" @click="save_file" tabindex="5"><v-icon small class="mr-2">fas fa-check</v-icon>Save</v-btn>
		</v-card-actions>
	</v-card></v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import SafariUpload from './SafariUpload'

export default {
	components: {  },
	props: {
		file_title: { type: String, required: true },
		filename: { type: String, required: true },
		original_contents: { type: String, required: false, default() { return '' } },
	},
	data() { return {
		dialog_open: true,
		html: ''
	}},
	computed: {
		...mapState(['small_screen']),
	},
	created() {
		this.html = this.original_contents
	},
	mounted() {
	},
	methods: {
		editor_config() {
			return U.get_froala_config({
				heightMin: 350,
				zIndex: 2501,
			})
		},

		save_file() {
			this.$store.dispatch('save_file', {filename: this.filename, html: this.html}).then(()=>{
				// once resource is saved, close dialog
				this.$emit('cancel')
			})
		},
	}
}
</script>

<style lang="scss">
</style>
