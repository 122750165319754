<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<div class="k-resource-link" :class="top_css_class" @mouseover="mouseover">
		<v-hover v-slot:default="{hover}"><div class="k-resource-link-inner" :class="hover?'k-resource-link-hovered':''">
			<div class="k-resource-link-description d-flex align-center">
				<div @click="details_showing=!details_showing" class="d-flex align-center">
					<img v-if="resource.provider" class="k-resource-type-provider-img" :src="provider_imgs[resource.provider]">
					<img v-if="!resource.provider&&resource.type=='safari'" class="k-resource-type-provider-img" :src="provider_imgs.safari">
					<v-icon v-if="!resource.provider&&resource.type!='safari'" class="k-resource-type-icon">fas {{icon}}</v-icon>
					<v-icon class="mx-1" color="brown">fas {{details_showing?'fa-angle-up':'fa-angle-down'}}</v-icon>
					<a v-html="resource.description" class="ml-1"></a>
				</div>
				<v-spacer/>
				<div v-if="assignment.status" class="ml-2 brown--text" style="white-space:nowrap">
					<div v-if="assignment.status=='pending'" @click="mark_assignment_complete"><span style="font-size:12px; font-weight:bold;"><!-- Mark Complete:--></span> <v-icon color="pink accent-3">far fa-square</v-icon> <v-icon small color="pink accent-3">fas fa-bolt</v-icon></div>
					<div v-if="assignment.status=='complete'" @click="complete_assignment_clicked"><span style="font-size:12px; font-weight:bold;"></span> <v-icon color="brown">fas fa-check-square</v-icon> <v-icon small color="brown">fas fa-bolt</v-icon></div>
				</div>
			</div>
			<div>
				<v-expand-transition><div v-show="details_showing" class="mt-2">
					<div v-show="resource.long_description" class="k-resource-link-long-description elevation-2" v-html="resource.long_description"></div>
					<div class="d-flex my-3 justify-center">
						<v-btn small color="primary" class="k-tight-btn" @click="resource_link_clicked"><v-icon small class="mr-1">fas fa-external-link-alt</v-icon>Open Resource</v-btn>

						<div v-if="!enable_edit_link">
							<v-btn small color="pink accent-3" dark class="k-tight-btn ml-4" v-if="!assignment.status" @click="create_assignment"><v-icon small class="mr-1">fas fa-bolt</v-icon>Assign</v-btn>
							<v-btn small color="brown" dark class="k-tight-btn ml-4" v-if="assignment.status=='complete'" @click="create_assignment"><v-icon small class="mr-1">fas fa-bolt</v-icon>Re-Assign</v-btn>
							<v-btn small color="brown" dark class="k-tight-btn ml-4" v-if="assignment.status=='pending'" @click="cancel_assignment"><v-icon small class="mr-1">fas fa-bolt</v-icon>Cancel Assignment</v-btn>
						</div>

						<v-btn small color="brown" dark class="ml-6" v-if="enable_edit_link" @click="$emit('edit_resource', resource)"><v-icon small class="mr-1">fas fa-edit</v-icon>Edit</v-btn>
						<v-btn small color="red" dark class="ml-1" v-if="enable_remove_link" @click="$emit('remove_resource', resource)"><v-icon small class="mr-1">fas fa-trash-alt</v-icon>Remove</v-btn>
					</div>
				</div></v-expand-transition>
			</div>
		</div></v-hover>

		<input style="display:none" class="k-copy-link-hidden-input" type="text" :value="reslink">
		<input style="display:none" class="k-copy-title-hidden-input" type="text" :value="copyable_title">
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import brainpop_img from './../../images/launch_brainpop3.png'
import okin_img from './../../images/launch_okin.png'
import ixl_img from './../../images/launch_ixl2.png'
import achieve3000_img from './../../images/launch_achieve30002.png'
import mindfulpractices_img from './../../images/launch_mindfulpractices4.png'
import streamablelearning_img from './../../images/launch_streamablelearning.png'
import safari_img from './../../images/launch_safari.png'

export default {
	components: { },
	props: {
		resource: { type: Object, required: true },
		enable_hc_assignment: { type: Boolean, required: false, default() { return false }},
		enable_edit_link: { type: Boolean, required: false, default() { return false }},
		enable_remove_link: { type: Boolean, required: false, default() { return false }},
		search_match: { type: String, required: false, default() { return '-' }},
		mark_district_unsanctioned: { type: Boolean, required: false, default() { return false }},
	},
	data() { return {
		show_inline_document: false,
		show_inline_html: false,
		show_long_description: false,
		inline_html: 'Loading...',
		retrieved_inline_html: false,
		inline_html_toggleable: false,
		details_showing: false,
		provider_imgs: {
			//espark: espark_img,
			brainpop: brainpop_img,
			ixl: ixl_img,
			achieve3000: achieve3000_img,
			mindfulpractices: mindfulpractices_img,
			//imaginelearning: imaginelearning_img,
			streamablelearning: streamablelearning_img,
			safari: safari_img,
		}
	}},
	computed: {
		...mapState(['small_screen', 'open_assignment_editor', 'lti_sso_status']),
		top_css_class() {
			let s = ''

			if (this.details_showing) s += ' k-resource-link--details-showing'

			if (this.resource.type == 'folder') return s + ' k-resource-link--folder'

			if (this.resource.teacher_facing) s += ' k-resource-link--teacher-facing'

			if (this.search_match == 'yes') s += ' k-resource-link--search_match'
			return s
		},
		reslink() {
			// link/url that we want to be used from the outside
			return 'https://odlss_portal.cps.edu/reslink/' + this.resource.resource_id
		},
		icon() {
			if (this.resource.type == 'website') return 'fa-link'
			if (this.resource.type == 'video' || this.resource.type == 'interactive') return 'fa-video'
			if (this.resource.type == 'upload') {
				let ext = this.resource.url.replace(/.*?\.(\w+)$/, '$1')
				if (ext == 'pdf') return 'fa-file-pdf'
				if (ext == 'doc') return 'fa-file-word'
				if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
			}
			if (this.resource.type == 'html') return 'fa-file-alt'
			if (this.resource.type == 'folder') return 'fa-folder'
			return 'fa-file'
		},
		copyable_title() {
			return $(sr('<div>$1</div>', this.resource.description)).text()
		},

		resource_folder_parent() {
			// hackish way to get the ancestor component that specifies the collection/unit this resource is in
			let parent = this.$parent
			while (parent) {
				if (parent.collection && parent.unit) {
					return parent
				}
				parent = parent.$parent
			}
			return false
		},

		// simple assignments using localstorage
		assignment: {
			get() {
				let val = this.$store.state.lst.resource_assignments[this.resource.resource_id]
				if (empty(val) || typeof(val) != 'object') return {}
				return val
			},
			set(val) {
				this.$store.commit('set', [this.$store.state.lst.resource_assignments, this.resource.resource_id, val])
				this.$store.commit('lst_set', 'resource_assignments')
			}
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		mouseover() {
			// for now don't worry about the long_description
			if (!this.resource.full_resource_data_loaded) {
				// this.get_resource_record(false)
			}
		},

		add_to_current_assignment() {
			// this won't be called unless we have an active open_assignment_editor
			this.open_assignment_editor.add_existing_resource(this.resource)
		},

		get_resource_record(and_launch) {
			this.$store.commit('set', [this.resource, 'full_resource_data_loaded', true])

		},

		resource_link_clicked() {
			// if type is 'safari', call get_resource_record to retrieve the lti_form, then launch
			if (this.resource.type == 'safari') {
				// (note that lti forms are timestamped, so we need to get a new form every time the link is launched)
				let payload = { resource_id: this.resource.resource_id, get_lti_form: 'yes' }
				this.$store.dispatch('get_resource_record', payload).then(result=>{
					// if we got back an lti_form, launch
					if (!empty(result.lti_form)) {
						// for the lti_form we open a new window and write out the form, which submits itself
						// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
						let w = window.open()
						w.document.write(result.lti_form)
					} else {
						// else we just open the new window to the resource's url (this shouldn't happen)
						window.open(this.resource.url, '_blank')
					}
				})

			} else if (this.resource.provider) {
				vapp.launch_sso_resource_link(this.resource.provider, this.resource.url)

			} else {
				console.log('OPENING URL (2): ' + this.resource.url)
				window.open(this.resource.url, '_blank')
			}
			this.$emit('resource_opened')
		},

		// vapp.$store.commit('lst_clear', 'resource_assignments')
		create_assignment() {
			if (!this.resource_folder_parent) {
				this.$alert('Couldn’t create assignment.')
				return
			}
			this.assignment = {
				collection_id: this.resource_folder_parent.collection.collection_id,
				collection_unit_id: this.resource_folder_parent.unit.collection_unit_id,
				status: 'pending'
			}
		},

		cancel_assignment() {
			if (!this.resource_folder_parent) {
				this.$alert('Couldn’t cancel assignment.')
				return
			}
			this.assignment = {
				collection_id: this.resource_folder_parent.collection.collection_id,
				collection_unit_id: this.resource_folder_parent.unit.collection_unit_id,
				status: ''
			}
		},

		mark_assignment_complete() {
			if (!this.resource_folder_parent) {
				this.$alert('Couldn’t mark assignment complete.')
				return
			}
			this.assignment = {
				collection_id: this.resource_folder_parent.collection.collection_id,
				collection_unit_id: this.resource_folder_parent.unit.collection_unit_id,
				status: 'complete'
			}
		},

		complete_assignment_clicked() {
			this.$alert('You’ve completed this assigned resource.')
		},
	}
}
</script>

<style lang="scss">
.k-resource-link {
	background-color:$v-brown-lighten-4;
	border-radius:6px;
	padding:2px 6px 2px 4px;
	position:relative;
	min-height:34px;
	white-space: normal;

	flex:1 1 auto;

	.k-resource-link-inner {
		// display:flex;
		// align-items: center;
		min-height:30px;
		position:relative;
		margin:4px;

		.k-resource-link-hovered {
			width:100%;
			position:absolute;
			top:20px;
			left:0;
			z-index:2;
			text-align:center;
		}

		.k-resource-link-teacher-facing-text {
			white-space:nowrap;
			color:#fff;
			font-weight:900;
			letter-spacing:1px;
			text-transform: uppercase;
			font-size:10px;
			background-color:rgba(2,136,209,0.7);
			padding:3px 5px;
			border-radius:3px;
		}
	}

	a {
		text-decoration:none;
		color:$v-brown!important;	// #b48a6b
		font-weight:bold;
		font-size:13px;
	}
	a span:hover {
		text-decoration:underline;
	}
	.v-icon.k-resource-type-icon {
		color:#fff!important;
		background-color:#026DAA;
		height:36px;
		width:36px;
		margin-right:4px;
		border-radius:18px;
		flex-shrink:0;	// without this the icon gets shrunk if the resource title is long
	}

	.k-resource-link-description {
		flex:1 1 auto;
		// margin-left:5px;
		// margin-top:-6px;
		word-break: break-word;
		line-height:1.1em;
		cursor:pointer;
	}

	.k-resource-link-gc-btn {
		img {
			height:18px;
		}
	}

	.k-resource-link-iframe {
		width:100%;
		height:100%;
		border:0;
		background-color:#fff;
	}

	.k-resource-link-inline-html {
		background-color:#fff;
		border-radius:8px;
		padding:5px 15px 15px 15px;
		font-size:14px;
	}
}

.k-resource-type-provider-img {
	height:36px;
	border-radius:4px;
	margin-right:4px;
}

// coordinate these widths with k-collections-units-outer-wrapper--unit-showing in Collection.vue
.k-resource-link--details-showing {
	width:632px;
}

@media (max-width: 1200px) {
	.k-resource-link--details-showing { width:492px; }
}

@media (max-width: 1100px) {
	.k-resource-link--details-showing { width:392px; }
}

@media (max-width: 1024px) {
	.k-collections-units-outer-wrapper--unit-showing { max-width:700px; }
	.k-collections-units-list { flex: 1 1 210px; }
	.k-collections-unit-content-wrapper { flex: 1 1 490px; }
}


.k-resource-link-hovered {
	z-index:200;
}

.k-resource-link--teacher-facing {
	background-color:$v-light-blue-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-light-blue-darken-2!important;
	}
}

.k-resource-link--adv {
	background-color:$v-purple-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-purple-darken-2!important;
	}
}

.k-resource-link--ese {
	background-color:$v-teal-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-teal-darken-2!important;
	}
}

.k-resource-link--not-district-sanctioned {
	background-color:$v-red-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-red-darken-3!important;
	}
}

.k-resource-link--unit-planning-guide {
	background-color:$v-green-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-green-darken-3!important;
		font-weight:900;
	}
}

.k-aspect-ratio-box--10-8 {
	height: 0;
	overflow: hidden;
	padding-top: 10px / 8px * 100%;
	background: white;
	position: relative;
}
.k-aspect-ratio-box-inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.k-resource-link-iframe-outer.k-aspect-ratio-box--10-8 {
	// use this so the iframe won't be too tall when it's wide
	padding-top: min(70vh, 125%);
}

// wrap a list of resources in this div to make them appear across the page
.k-resources-list {
	display:flex;
	justify-content: space-between;
	flex-wrap:wrap;
}
// this makes it so items in the final "row" of resources don't stretch across the page if they shouldn't do so
.k-resources-list::after {
	content: '';
	flex-grow: 1000000000;
}

.k-resource-link-long-description {
	background-color:#fff;
	padding:2px 6px;
	border-radius:6px;
	max-width:450px;
	font-size:14px;
	line-height:18px;
	margin:4px auto 8px auto;
}

.k-resource-link--search_match {
	background-color:$v-yellow-lighten-3!important;
	border:2px solid $v-yellow-darken-1!important;
	padding:2px 6px 2px 4px;
}

.k-resource-link--folder {
	background-color:$v-grey-lighten-3!important;
}

</style>
