class Assignment {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'assignment_id', 0)

		// arbitrary uuid to use as the key in components; removed before saving
		this.key_uuid = U.new_uuid()

		sdp(this, data, 'assigned_to', [])
		sdp(this, data, 'description', '')
		sdp(this, data, 'teacher_notes', '')
		sdp(this, data, 'case_identifiers', [])

		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				this.resources.push(new Resource(r))
			}
		} else if (!empty(data.url)) {
			this.resources.push(new Resource({url: data.url}))
		}

		sdp(this, data, 'assignment_type', 'none')	// currently the other options are 'manual' 'cr'; we'll add more later
		sdp(this, data, 'submission_formats', ['html'])	// other options are 'upload' and 'link'
		sdp(this, data, 'response_validation', 'off', ['off', 'lenient', 'normal', 'stingy'])
		sdp(this, data, 'response_template', '')
		sdp(this, data, 'auto_grade', 'off', ['off', 'half', 'full'])
		sdp(this, data, 'comparison_text', '')

		sdp(this, data, 'points', 0)
		sdp(this, data, 'gb_category', 'ungraded')	// other options are 'practice' and 'assessment'
		sdp(this, data, 'pinned', false)

		sdp(this, data, 'collection_id', '')
		sdp(this, data, 'collection_unit_id', 0)

		sdp(this, data, 'creator', '')

		// convert dates from mysql to timestamp if necessary
		let tzo = new Date().getTimezoneOffset()*60*1000	// dates come in GMT; convert to local

		if (!isNaN(data.start_date*1)) this.start_date = data.start_date*1
		else this.start_date = (empty(data.start_date)) ? 0 : (date.parse(data.start_date, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		if (!isNaN(data.due_date*1)) this.due_date = data.due_date*1
		else this.due_date = (empty(data.due_date)) ? 0 : (date.parse(data.due_date, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		if (!isNaN(data.edited_date*1)) this.edited_date = data.edited_date*1
		else this.edited_date = (empty(data.edited_date)) ? 0 : (date.parse(data.edited_date, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		if (!isNaN(data.created_at*1)) this.created_at = data.created_at*1
		else this.created_at = (empty(data.created_at)) ? 0 : (date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		// add incoming assignees
		this.assigned_to = []
		if (!empty(data.assigned_to)) for (let a of data.assigned_to) {
			this.assigned_to.push(new window.Assignee(a))
		}

		// add assignment_result's if we got any; indexed by student_sourcedId
		this.assignment_result = {}
		if (!empty(data.assignment_result)) for (let student_sourcedId in data.assignment_result) {
			this.assignment_result[student_sourcedId] = new window.Assignment_Result(data.assignment_result[student_sourcedId])
		}
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)

		delete o.component
		delete o.editing

		// remove full resources object; attach resource_ids as a comma-delimited string
		delete o.resources
		if (this.resources.length > 0) {
			o.resource_ids = ''
			for (let r of this.resources) {
				if (o.resource_ids != '') o.resource_ids += ','
				o.resource_ids += r.resource_id
			}
		}

		delete o.case_identifiers
		delete o.assigned_to
		delete o.key_uuid
		// we don't want to send empty strings or 0s in to the service for certain things
		if (o.start_date == 0) delete o.start_date
		if (o.due_date == 0) delete o.due_date
		if (o.edited_date == 0) delete o.edited_date
		if (o.created_at == 0) delete o.created_at
		if (empty(o.resource_ids)) delete o.resource_ids
		if (empty(o.collection_unit_id)) delete o.collection_unit_id
		return o
	}

	start_date_passed(now) {
		if (empty(now)) now = Math.round(new Date().getTime() / 1000)
		// we consider the start date to be passed (i.e. the assignment is open to students) if there is no start date, or if the start date is <= now
		// add a 2-minute buffer to now for this, in case the server time is off a bit from the local computer time
		return this.start_date == 0 || (this.start_date - 120) <= now
	}

	due_date_passed(now) {
		if (empty(now)) now = Math.round(new Date().getTime() / 1000)
		return this.due_date > 0 && this.due_date <= now
	}

	status_color(student_view, result, now) {
		if (student_view) {
			// return empty string for assignment_type none
			if (this.assignment_type == 'none') {
				return ''
			} else if (result.complete()) {
				return 'green'
			} else if (result.viewed()) {
				return 'lime'
			} else if (this.due_date_passed(now)) {
				return 'red'
			} else if (!this.start_date_passed(now)) {
				return 'grey'	// these shouldn't be shown for students
			} else {
				return 'amber'
			}

		} else {
			// teacher statuses

			// not started or not assigned to students: grey
			if (!this.start_date_passed(now) || this.assignment_type == 'none') {
				return 'grey'

				// past-due: blue
			} else if (this.due_date_passed(now)) {
				return 'blue'

				// started, > 40% of students complete: lime
			} else if (false) {
				return 'lime'

				// started, > 70% of students complete: green
			} else if (false) {
				return 'green'

				// started, < 10% of students complete: amber
			} else {
				return 'amber'
			}
		}
	}

	display_title(remove_bold) {
		if (empty(this.description)) return ''
		let title = this.description.replace(/^<p.*?>(.*?)<\/p>.*/, '$1')
		title = title.replace(/^<h3.*?>(.*?)<\/h3>.*/, '$1')
		title = title.replace(/<img.*?>|<(\/)?p\b.*?>|<br>/g, ' ')

		if (remove_bold === true) {
			title = title.replace(/<(\/?)(b|strong)>/g, '')
		}
		return title
	}
}
window.Assignment = Assignment

class Assignee {
	constructor(data, asn) {
		if (empty(data)) data = {}
		sdp(this, data, 'assignment_student_mapping_id', 0)
		sdp(this, data, 'class_sourcedId', '')
		sdp(this, data, 'user_sourcedId', '')
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		// we *don't* send assignment_student_mapping_id for assignees we want to save
		delete o.assignment_student_mapping_id
		return o
	}

	matches(other_assignee) {
		return (this.class_sourcedId == other_assignee.class_sourcedId
			&& this.user_sourcedId == other_assignee.user_sourcedId)
	}
}
window.Assignee = Assignee

class Assignment_Result {
	constructor(data, asn) {
		if (empty(data)) data = {}
		sdp(this, data, 'assignment_id', '')
		if (empty(this.assignment_id) && !empty(asn)) this.assignment_id = asn.assignment_id

		sdp(this, data, 'student_sourcedId', '')

		sdp(this, data, 'status', '', ['', 'viewed', 'started', 'complete'])
		sdp(this, data, 'score', 0)			// 0-1 (decimal)
	}

	viewed() {
		return (this.status != '')
	}

	started() {
		return (this.status == 'started' || this.status == 'complete')
	}

	complete() {
		return (this.status == 'complete')
	}
}
window.Assignment_Result = Assignment_Result
