<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-collection-unit">
	<v-expand-transition><div><div class="d-flex justify-center">
		<div style="flex:1 1 100%;max-width:1000px">
			<div class="k-collections-unit-description elevation-3" v-if="unit.text" v-html="unit.text"></div>
			<div class="k-collection-unit-wrapper-outer">
				<div>
					<div class="k-collection-unit-wrapper">
						<div>
							<div class="mb-6">
								<div class="k-collection-unit-resources">
									<CollectionResourceFolder :unit="unit" :folder_id="'top'" :enable_editing="false" />
								</div>
							</div>
						</div>

						<div class="text-center">
							<v-btn color="primary" class="k-tight-btn k-nocaps-btn" @click="search_safari"><v-icon class="mr-2">fas fa-search</v-icon> Search SAFARI Montage for more resources related to this topic</v-btn>
						</div>

						<div v-if="is_admin && !show_unit_editor" class="text-right mt-5 mb-2"><v-btn text color="secondary" @click="show_unit_editor=true"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Unit</v-btn></div>

						<CollectionUnitEdit ref="unit_editor_component" v-if="show_unit_editor" :collection="collection" :original_unit="unit" @editor_cancel="show_unit_editor=false" />
					</div>
				</div>

			</div>
		</div>
	</div></div></v-expand-transition>
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionResourceFolder from './CollectionResourceFolder'
import CollectionUnitEdit from './CollectionUnitEdit'

export default {
	components: { CollectionResourceFolder, CollectionUnitEdit, },
	props: {
		collection: { type: Object, required: true },
		unit: { type: Object, required: true },
		is_admin: { type: Boolean, required: true },
	},
	data() { return {
		show_unit_editor: false,
	}},
	watch: {
	},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
	},
	methods: {
		narrow_window() {
			return $(window).width() < 1000
		},

		search_safari() {
			// for safari keywords, sent unit title, or search_keywords if explicitly specified (see CollectionUnitEdit)
			let keywords = this.unit.title
			if (this.unit.search_keywords) keywords = this.unit.search_keywords

			let payload = {
				keywords: keywords,
			}
			// safari maps "PK" on to -1 and "K" on to 0, so we can translate from grade indices to the values safari wants by subtracting 1 (PK = -1; K = 0; 1 = 1; etc.)
			payload.grade_start = U.grade_value(this.collection.grade_low) - 1
			payload.grade_end = U.grade_value(this.collection.grade_high) - 1

			vapp.search_safari(payload)
		},
	}
}
</script>

<style lang="scss">

.k-collections-unit-description {
	font-size:14px;
	line-height:19px;
	padding:4px 8px;
	margin:8px 16px 12px 16px;
	border-radius:8px;
	border:1px solid #ccc;
}

.k-collection-unit {
	font-size:16px;
	padding:8px;
}

@media (max-width: 1300px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: $screen-sm) {
}

.k-collection-unit-wrapper-outer {
	max-width:1000px;
	min-height:300px;

	.k-collection-unit-wrapper {
		width:auto;
		margin:8px;
		background-color:#fff;
		border-radius:10px;
		overflow:auto;

		.k-collection-unit-section-header {
			font-weight:bold;
			background-color:$v-blue-darken-2;
			border-radius:5px;
			padding:2px 8px;
			color:#fff;
			font-size:20px;
		}

		.k-collection-unit-description {
			// max-width:700px;
			font-size:16px;
			// background-color:$v-light-blue-lighten-5;
			// border-radius:5px;
			// padding:4px 12px;
			margin:20px auto;
		}

		.k-collection-unit-resources {
			// max-width:700px;
			margin:0 auto;
		}
	}
}

@media (max-width: 768px) {
	.k-collection-unit-wrapper {
		margin-top:0;
	}

	.k-collection-unit-wrapper-outer {
		.k-collection-unit-wrapper {
			margin-top:0;
		}
	}
}

</style>
