<template><div>

	<div class="k-main-welcome">
		<div class="k-main-welcome__time-date k-shadow-text" v-html="time_string"></div>
		<!-- <div class="k-main-welcome__question k-shadow-text">
			<div><b class="amber--text">Good {{ time_of_day }}, {{user_info.first_name}}.</b></div>
		</div> -->
		<div class="k-main-top-instructions">Browse or search for learning resources below.<br>Click the <v-icon small color="#fff" class="mx-1">fas fa-users</v-icon> button at left to visit the Parent Center.</div>

		<SiteSearch />

		<!-- educ8 aquired okin, launches the same way -->
		<div class="k-elevated k-main-collection pb-8">
			<div class="k-resource-one-offs-holder">
				<v-spacer/>
				<div class="k-resource-one-off" @click="launch('okin', 'https://i-educ8.com/')">
					<img src="./../../images/launch_educ8_hh.png">
					<div><b>Educ8<br/>Homework Helpline</b></div>
					<div style="margin-top:4px; font-size:14px;">Just In Time Academic Support</div>
				</div>
				<!--<div class="k-resource-one-off" @click="launch('okin', 'https://www.okineducate.com/odlss-targeted-tutoring/')" style="margin-left:2%;margin-right:2%;">
					<img src="./../../images/launch_okin_tt.png">
					<div><b>OKIN<br/>Targeted Tutoring</b></div>
					<div style="margin-top:4px; font-size:14px;">Just In Time Academic Support</div>
				</div>-->
				<div class="k-resource-one-off" @click="launch('learnaroundtheworld', 'https://learnaroundtheworld.org/cps-odlss/events/')" style="margin-left:8%;margin-right:8%;">
					<img src="./../../images/launch_latw.png">
					<div><b>Learn Around the World</b></div>
					<div style="margin-top:4px; font-size:14px;">Virtual Field Trips</div>
				</div>
				<div class="k-resource-one-off" @click="launch('streamablelearning', 'https://www.streamablelearning.com/app/odlss/')">
					<img src="./../../images/launch_streamablelearning.png">
					<div><b>Streamable Learning</b></div>
					<div style="margin-top:4px; font-size:14px;">Virtual Field Trips</div>
				</div>
				<v-spacer/>
			</div>

			<div class="k-main-collection-header">
				<h2>Resource Collections</h2>
			</div>
			<div v-if="!new_collection">
				<div v-for="(category) in collection_categories" class="k-collection-list-category">
					<div class="k-collection-list-category-title" v-html="category.title"></div>
					<div v-if="category.collections.length==0">There are currently no resource collections available.</div>
					<div v-else class="k-collection-list-category-collections">
						<div v-for="(collection) in category.collections" @click="go_to_collection(collection)" class="k-elevated k-main-collection-item k-collection-tile" style="position:relative" :class="collection_css(collection)">
							<div class="text-center"><img class="k-collection-list-title-icon" :src="collection_title_img[collection.title]?collection_title_img[collection.title]:collection_title_img.misc"></div>
							<div class="k-collection-list-title-wrapper">
								<div class="k-collection-list-title" v-html="collection_title(collection)"></div>
							</div>
							<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-show="collection_assignment_count[collection.collection_id]>0" class="k-collection-list-assignment-total">{{collection_assignment_count[collection.collection_id]}}</div></template>{{collection_assignment_count[collection.collection_id]}} Assigned {{collection_assignment_count[collection.collection_id]==1?'Resource':'Resources'}}</v-tooltip>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!new_collection&&is_admin" class="mt-4 text-center">
				<v-btn @click="create_collection"><v-icon small class="mr-2">fas fa-plus</v-icon>Create a New Resource Collection</v-btn>
			</div>

			<CollectionEdit v-if="new_collection" :collection="new_collection" @editor_cancel="new_collection=null"></CollectionEdit>
		</div>
	</div>
	<SafariSearch v-if="show_safari_search" @dialog_done="show_safari_search=false"></SafariSearch>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import SiteSearch from '../resources/SiteSearch'
import SafariSearch from '../resources/SafariSearch'
import CollectionEdit from './CollectionEdit'

export default {
	components: { SafariSearch, CollectionEdit, SiteSearch },
	data() { return {
		show_safari_search: false,
		time_string: '',
		time_string_colon: true,
		time_of_day: 'day',
		new_collection: null,
	}},
	computed: {
		...mapState(['user_info', 'all_collections']),
		is_admin() {
			return this.user_info.role == 'admin'
		},
		collection_title_img() { return vapp.collection_title_img },
		collection_categories() {
			// hard-code the categories we're using for ODLSS
			let pre_arr = [
				{title: 'Grades Pre-K to 2', grade_low: 'PK', grade_high: '2', collections: []},
				{title: 'Grades 3 to 5', grade_low: '3', grade_high: '5', collections: []},
				{title: 'Grades 6 to 8', grade_low: '6', grade_high: '8', collections: []},
				{title: 'Grades 9 to 12', grade_low: '9', grade_high: '12', collections: []},
			]

			// only include categories for this user
			let arr = []
			for (let cat of pre_arr) {
				if (this.is_admin || this.user_can_see_category(cat)) arr.push(cat)
			}

			for (let collection of this.all_collections) {
				// when we first create a new collection to be entered, its title will be false
				if (collection.title == '') return false

				// for inactive collection's, only show to admins
				if (collection.active == 'no' && !this.is_admin) return false

				for (let cat of arr) {
					// if collection/category intersect, push onto collections list
					if (U.grades_include(cat, collection.grade_low, collection.grade_high)) {
						cat.collections.push(collection)
					}
				}
			}

			// collections will be sorted by title when they're loaded in store, so need to sort here
			return arr
		},
		collections() {
			return this.all_collections
			let arr = this.all_collections.filter(collection => {
				// when we first create a new collection to be entered, its title will be false
				if (collection.title == '') return false

				// for inactive collection's, only show to admins
				if (collection.active == 'no' && !this.is_admin) return false

				// TODO: return true iff this user is allowed to see this collection
				return true
			})

			arr.sort((a,b)=>{
				// first sort by grade_low
				let gd = U.grade_value(a.grade_low) - U.grade_value(b.grade_high)
				if (gd != 0) return gd

				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		collection_assignment_count() {
			let o = {}
			for (let resource_id in this.$store.state.lst.resource_assignments) {
				let a = this.$store.state.lst.resource_assignments[resource_id]
				if (empty(a) || typeof(a) != 'object') continue

				if (a.status != 'pending') continue

				if (empty(o[a.collection_id])) {
					o[a.collection_id] = 0
				}
				++o[a.collection_id]
			}
			return o
		},
	},
	created() {
		this.calculate_time_string();
		setInterval(()=>{
			this.calculate_time_string();
		}, 1000)
	},
	mounted() {
	},
	methods: {
		user_can_see_category(cat) {
			// determine whether or not this user can see this category, based on their grade level
			for (let grade of this.user_info.grade_levels) {
				if (U.grades_include(cat, grade)) return true
			}
			return false
		},

		calculate_time_string() {
			// this.time_string_colon = !this.time_string_colon
			let d = new Date()

			let hour = d.getHours()

			if (hour > 2 && hour < 12) this.time_of_day = 'morning'
			else if (hour < 18) this.time_of_day = 'afternoon'
			else this.time_of_day = 'evening'

			hour = hour % 12
			if (hour == 0) hour = 12

			let minute = d.getMinutes()
			if (minute < 10) minute = '0' + minute

			// Thu Jan 29&nbsp;&nbsp;10:50
			this.time_string = sr('$1&nbsp;&nbsp;$2 $3&nbsp;&nbsp;$4<span style="$5">:</span>$6',
				['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][d.getDay()],
				['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()],
				d.getDate(),
				hour,
				this.time_string_colon ? '' : 'visibility:hidden',
				minute,
			)
		},

		collection_css(collection) {
			let s = U.subject_tile_css(collection)
			if (collection.title.length > 50) {
				s += ' k-collection-tile-extra-long-title'
			} else if (collection.title.length > 30) {
				s += ' k-collection-tile-long-title'
			}
			if (collection.active == 'no') {
				s += ' k-collection-tile-inactive'
			}
			return s
		},

		collection_title(collection) {
			// add <wbr> tags after slashes
			let s = collection.title.replace(/\//g, '/<wbr>')
				if (collection.active == 'no') {
					s += ' <b class="red--text">[Inactive]</b>'
				}

			return s
		},

		go_to_collection(collection) {
			this.$store.commit('set', ['last_collection_list', 'index'])
			this.$router.push({ path: sr('/collections/$1/0', collection.collection_id) })
		},

		create_collection() {
			this.new_collection = new Collection()
			return
		},

		launch(target, url) {
			if (empty(url)) {
				vapp.lti_sso(target, 'new_window')
			} else {
				vapp.lti_sso(target, x=>window.open(url, '_blank'))
			}
		},

	}
}
</script>

<style lang="scss">
.k-collection-list-category {
	// border-radius:12px;
	border-top:1px solid $secondary-color;
	padding-top:12px;
	margin-top:12px;
	// background-color:#fff;
}

.k-collection-list-category-title {
	font-weight:bold;
	margin-bottom:4px;
}

.k-collection-list-category-collections {
	display:flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items:center;
	justify-content: center;
	clear:both;
}

.k-collection-tile {
	width:130px!important;
	height:130px!important;
	border-radius:12px!important;
	text-align:center;
	// hyphens: auto;	// this doesn't seem to really help; e.g. it doesn't break "microeconomics"
	.k-class-tile-course-code {
		position:absolute;
		left:0px;
		bottom:10px;
		font-size:9px;
		width:100%;
		text-align:center;
	}
}

.k-collection-list-title-icon {
	height:70px;
	width:70px;
	border-radius:8px;
}

.k-collection-list-assignment-total {
	position:absolute;
	right:-5px;
	top:-5px;
	font-size:14px;
	font-weight:bold;
	background-color:$v-pink-accent-3;
	color:#fff;
	width:26px;
	height:26px;
	border-radius:26px;
	line-height:26px;
	text-align:center;
}

.k-collection-list-title-wrapper {
	display:flex;
	flex-direction: column;
	justify-content: center;
	min-height:30px;
}

.k-collection-list-title {
	font-size:12px!important;
	line-height:14px!important;
	font-weight:bold;
}

.k-collection-list-title-no-icon {
	font-size:14px!important;
	line-height:18px!important;
}

.k-main-welcome {
	text-align:center;

	.k-main-welcome__time-date {
		font-family:$display-font;
		font-weight:900;
		font-size:40px;
		margin-top:30px;
	}

	.k-main-welcome__question {
		// margin-top:10px;
		font-size: 35px;
		line-height:40px;
		font-weight:bold;
	}

	.k-main-top-instructions {
		background-color:$v-light-blue-darken-2;
		color:#fff;
		font-size:18px;
		text-align:center;
		font-weight:bold;
		padding:12px;
		max-width:520px;
		margin:20px auto 0 auto;
		border-radius:8px;
	}
}

@media (max-width: $screen-sm) {
	.k-class-tile {
		width:160px!important;
		height:90px!important;

		img {
			width:22px;
		}

		.k-class-edit-btn {
			right:10px;
			bottom:4px;
		}
	}

	.k-collection-tile {
		width:100px!important;
		height:100px!important;
		border-radius:50px!important;
		font-size:12px!important;
		line-height:15px!important;
		// .k-class-tile-course-code {
		// 	position:absolute;
		// 	left:0px;
		// 	bottom:10px;
		// 	font-size:9px;
		// 	width:100%;
		// 	text-align:center;
		// }
	}
}

.k-resource-one-offs-holder {
	display: flex;
	align-items: flex-start;
	justify-content: stretch;
	border-bottom:1px solid $secondary-color;
	padding-bottom:12px;
	margin-bottom:12px;
}

.k-resource-one-off {
	align-self: stretch;
	max-width:200px;
	border:1px solid #000;
	border-radius:12px;
	// background-color:rgba(255, 250, 240);
	background-color:#fff;
	font-size:16px;
	line-height:20px;
	margin:12px;
	padding:12px;
	cursor:pointer;

	img {
		border-radius:10px;
		width:calc(60% - 12px);
		margin-bottom:4px;
		// margin:12px;
	}
}
</style>
