// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class User_Info {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'user_id', 0)
		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')
		sdp(this, data, 'email', '')
		sdp(this, data, 'system_role', 'student', ['student', 'staff', 'parent', 'admin', 'unknown'])
		sdp(this, data, 'role', this.system_role)
		sdp(this, data, 'sis_identities', [])
		sdp(this, data, 'admin_rights', [])

		// grade levels (array, e.g. ['PK', '3']); determines which collections the user has access to
		sdp(this, data, 'grade_levels', [])
	}
}
window.User_Info = User_Info
