import '@/js/utilities.js'	// do this first so router et al have access to U functions
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import DialogPromise from '@/components/vuetify-dialog-promise-pwet'
Vue.use(DialogPromise)

import date from 'date-and-time'
window.date = date

// jquery
window.$ = require('jquery')
window.jQuery = window.$

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

import VueVisible from 'vue-visible'
Vue.use(VueVisible)

import froala from '@/js/froala-plugin'

// import froala from '@/plugins/froala-plugin'

import '@/js/textfill.js'
import '@/scss/base.scss'

// data structures
import '@/js/user_info.js'
import '@/js/resources.js'
import '@/js/assignments.js'
import '@/js/collections.js'
import '@/js/links.js'

import '@/js/sample_data.js'

Vue.config.productionTip = false

new Vue({
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount('#app')
