// Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams

// fn for returning the "value" for a grade -- what we use to order the grades
U.grade_value = function(grade) {
	return vapp.$store.state.grades.findIndex(g=>g==grade)
}

// fn for returning a "display value" for a grade
U.grade_display_value = function(grade) {
	let text
	if (grade == 'K') return 'Kindergarten'
	else if (grade == 'PK') return 'Pre-K'
	else if (!isNaN(grade*1)) return 'Grade ' + grade
	else return grade
}

// fn for determining if the given object o, which must include a grade_low and grade_high parameter, "includes" the given grade
U.grades_include = function(o, grade_low, grade_high) {
	// if object doesn't have a grade_low or grade_high, return false
	if (empty(o.grade_low) || empty(o.grade_high)) return false

	// try to get the index of the given grade_low and o's grade_low and grade_high from the store's grades array; if any not found return false
	let grade_low_index = U.grade_value(grade_low)
	let o_grade_low_index = U.grade_value(o.grade_low)
	let o_grade_high_index = U.grade_value(o.grade_high)

	if (empty(grade_low_index)) return false
	if (empty(o_grade_low_index)) return false
	if (empty(o_grade_high_index)) return false

	// grade_high is optional; if not provided, base just on grade_low
	if (!grade_high) {
		return (grade_low_index >= o_grade_low_index && grade_low_index <= o_grade_high_index)
	} else {
		let grade_high_index = U.grade_value(grade_high)
		if (empty(grade_high_index)) return false

		// if we have a grade_low and a grade_high...
		// return false if grade_high is < o_grade_low
		if (grade_high_index < o_grade_low_index) return false
		// return false if grade_low is > o_grade_high
		if (grade_low_index > o_grade_high_index) return false
		// else return true
		return true
	}
}

// for sorting such that "core collections" come first
U.is_core_collection = function(title) {
	if (title == 'English Language Arts') return 1
	if (title == 'Math') return 1
	if (title == 'Science') return 1
	if (title == 'Social Science') return 1
	if (title == 'Social and Emotional Learning') return 1
	return 2
}

// fn for consistently coloring things that have titles that might include subject words -- e.g. classes or learning progressions
U.subject_tile_css = function(o) {
	let n = (o.color_index % 13) + 1
	return 'k-list-color-' + n
}



class Collection {
	constructor(data) {
		if (empty(data)) data = {}

		// flag to specify whether or not the collection's data (e.g. units and resources) has been fully loaded; assume true
		sdp(this, data, 'data_loaded', true)

		sdp(this, data, 'collection_id', 0)
		sdp(this, data, 'title', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'grade_low', '')
		sdp(this, data, 'grade_high', '')
		sdp(this, data, 'subject', '')
		sdp(this, data, 'active', 'yes')	// when set to 'no', only admins can see it

		// color_index for use in calculating color to use
		sdp(this, data, 'color_index', 0)

		// normalize subject capitalization
		this.subject = U.capitalize_words(this.subject)

		this.units = []
		console.log(data.units)
		if (!empty(data.units)) {
			for (let u of data.units) {
				this.units.push(new Collection_Unit(u))
			}
		}
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)

		o.units = []
		for (let unit of this.units) {
			// this removes assignments from collection units when we save
			o.units.push(unit.copy_for_save())
		}
		delete o.data_loaded
		delete o.color_index

		return o
	}
}
window.Collection = Collection

class Collection_Unit {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'collection_unit_id', 0)
		sdp(this, data, 'title', '')	// This will be, e.g., "Unit 1"
		sdp(this, data, 'description', '')
		sdp(this, data, 'text', '')
		sdp(this, data, 'search_keywords', '')

		// this structure is for holding a tree structure for resources
		if (!empty(data.resource_tree)) {
			this.resource_tree = $.extend(true, {}, data.resource_tree)
		} else {
			this.resource_tree = {
				folders: [{
					folder_id: 'top',
					title: '',
					parent_folder_id: '',
					seq: 0,
				}],
				folder_assignments: []	// {resource_id: 'xxx', parent_folder_id: 'xxx', seq: 0}
			}
		}

		// individual resources associated with the unit
		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				r = new Resource(r)
				this.resources.push(r)
			}
		}
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)

		// remove full resource objects; attach resource_ids instead
		delete o.resources
		o.resource_ids = []
		for (let r of this.resources) {
			o.resource_ids.push(r.resource_id)
		}

		// remove resource_tree if there aren't any folder_assignments???
		// PW 4/30/2021: when I was doing this, it made things so you can't create a bunch of empty folders at the start
		// for ODLSS, every collection will have a resource_tree, so I don't think we need this
		// if (o.resource_tree.folder_assignments.length == 0) {
		// 	delete o.resource_tree
		// }

		return o
	}
}
window.Collection_Unit = Collection_Unit
