<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<SiteSearch  />

	<div class="k-collections-view-wrapper k-page-wrapper" :class="top_css_class">

		<h2 class="k-page-title d-flex align-center">
			<div v-if="initialized==0">LOADING…</div>
			<img v-if="initialized" class="k-collection-view-collection-icon" :src="collection_title_img[collection.title]?collection_title_img[collection.title]:collection_title_img.misc">
			<div v-if="initialized" v-html="page_title"></div>
			<div><b v-if="initialized&&collection.active=='no'" class="ml-8 red--text">[Inactive]</b></div>
			<v-spacer/>

			<v-menu bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-if="is_admin" class="k-edit-btn ml-2" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
				<v-list>
					<v-list-item v-if="is_admin" @click="edit_collection"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit Resource Collection</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</h2>

		<div v-if="initialized" v-show="!show_collection_editor">

			<div class="k-collections-collection-description-wrapper d-flex">
				<div class="k-collections-collection-back-button"><v-icon @click="up_one_level()" large>fas fa-arrow-circle-left</v-icon></div>
				<div v-show="collection.description" class="k-collections-collection-description elevation-3" v-html="collection.description"></div>
				<div class="k-collections-collection-back-button"></div>
			</div>

			<div class="k-collections-units-outer-wrapper elevation-3" :class="unit_showing?'k-collections-units-outer-wrapper--unit-showing '+collection_css_class_light:''">
				<div class="k-collections-units-list">
					<div v-for="(unit, i) in collection.units" class="k-collections-unit-list-item" :class="unit_showing==unit?'k-collections-unit-list-item--showing'+unit_css_class[i]:unit_css_class[i]">
						<div class="k-collections-unit-title" @click="toggle_unit(unit)">
							<v-btn v-if="unit_showing!=unit" color="primary" icon><v-icon>fas fa-folder</v-icon></v-btn>
							<v-btn v-if="unit_showing==unit" color="primary" icon><v-icon>fas fa-folder-open</v-icon></v-btn>
							<div class="k-collections-unit-list-item-title">{{unit.title}}</div>
							<v-spacer />
							<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-show="unit_assignment_count[unit.collection_unit_id]>0" class="k-collections-unit-list-item-assignment-total">{{unit_assignment_count[unit.collection_unit_id]}}</div></template>{{unit_assignment_count[unit.collection_unit_id]}} Assigned {{unit_assignment_count[unit.collection_unit_id]==1?'Resource':'Resources'}}</v-tooltip>
						</div>
					</div>
				</div>

				<div v-show="unit_showing" class="k-collections-unit-content-wrapper">
					<CollectionUnitResources v-if="unit_showing"
						:collection="collection" :unit="unit_showing"
						:is_admin="is_admin"
						@hide_unit="hide_unit"
					></CollectionUnitResources>
				</div>
			</div>

		</div>
		<CollectionEdit v-if="show_collection_editor" :collection="collection" @editor_cancel="show_collection_editor=false"></CollectionEdit>
	</div>
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionUnitResources from './CollectionUnitResources'
import CollectionEdit from './CollectionEdit'
import SiteSearch from '../resources/SiteSearch'

export default {
	components: { CollectionUnitResources, CollectionEdit, SiteSearch },
	props: {
		// note that these two props come from vuerouter
		collection_id: { type: String, required: true },
		unit_id: { type: String, required: true },
	},
	data() { return {
		initialized: 0,
		unit_showing_val: null,
		show_collection_editor: false,
	}},
	computed: {
		...mapState(['user_info', 'all_collections', 'all_assignment_results', 'student_sim_view', 'simulated_results', 'collection_update_trigger']),
		collection_title_img() { return vapp.collection_title_img },
		user_is_teacher() {
			return this.user_info.role == 'staff'
		},
		is_admin() {
			return this.user_info.role == 'admin'
		},
		collection() {
			if (this.initialized < 0) return {}

			// we always pull from all_collections here, not the user's individual courses
			let l = this.all_collections.find(o=>o.collection_id == this.collection_id)
			if (empty(l)) return {}
			return l
		},
		page_title() {
			return sr('<b>$1</b> ($2 to $3)', this.collection.title, U.grade_display_value(this.collection.grade_low), U.grade_display_value(this.collection.grade_high))
		},
		top_css_class() {
			let s = U.subject_tile_css(this.collection)//+'-light'
			// let s = ''
			if (this.unit_showing) s += ' k-collections-view-wrapper--unit-showing'

			return s
		},
		collection_css_class_light() {
			let s = U.subject_tile_css(this.collection) + '-light'
			return s
		},
		unit_css_class() {
			if (empty(this.collection.units)) return []
			let arr = []
			for (let i = 0; i < this.collection.units.length; ++i) {
				let s = ''
				if (i == 0) s += ' k-collections-unit--first'
				if (i == this.collection.units.length-1) s += ' k-collections-unit--last'

				arr.push(s)
			}
			return arr
		},
		unit_showing() {
			if (empty(this.collection.units)) return

			// if unit_id (which comes from the url via vuerouter) is something other than 0, get the unit to show;
			// otherwise use the last unit showing (which could be null)
			if (this.unit_id != 0) {
				let u = this.collection.units.find(x=>x.collection_unit_id == this.unit_id)
				if (empty(u)) return null
				this.unit_showing_val = u
			}
			return this.unit_showing_val
		},
		unit_assignment_count() {
			// console.log('updating unit_assignment_count')
			let o = {}
			for (let resource_id in this.$store.state.lst.resource_assignments) {
				let a = this.$store.state.lst.resource_assignments[resource_id]
				if (empty(a) || typeof(a) != 'object') continue

				if (a.status != 'pending') continue

				if (empty(o[a.collection_unit_id])) {
					o[a.collection_unit_id] = 0
				}
				++o[a.collection_unit_id]
			}
			return o
		},
	},
	created() {
		vapp.collection_component = this
	},
	mounted() {
		this.initialize()
	},
	methods: {
		initialize() {
			// if we don't already have the full collection data for this course...
			if (!this.collection.data_loaded) {
				// if title is empty and the user is an admin, we must be creating it now, so go right to the editor
				if (this.collection.title == '' && this.is_admin) {
					++this.initialized
					this.edit_collection()
					return
				}

				// try to load it now
				this.$store.dispatch('get_collection', this.collection_id).then((found)=>{
					if (!found) {
						this.$alert('This collection id was not found.')
						return
					}

					this.$nextTick(()=>{
						++this.initialized
					})

				}).catch(()=>{
					this.$alert('error in get_collection?')
				})

			} else {
				++this.initialized
			}
		},

		hide_unit() {
			if (!empty(this.unit_showing)) {
				// when we hide the unit, we have to set unit_showing_val to null, but after a tick to let unit_id get to 0
				this.$nextTick(()=>this.unit_showing_val = null)
				this.$router.push({ path: sr('/collections/$1/$2', this.collection_id, 0) })
			}
		},

		show_unit(unit) {
			if (empty(this.unit_showing) || unit != this.unit_showing) {
				this.$router.push({ path: sr('/collections/$1/$2', this.collection_id, unit.collection_unit_id) })
			}
		},

		toggle_unit(unit) {
			if (!empty(this.unit_showing) && unit == this.unit_showing) this.hide_unit()
			else this.show_unit(unit)
		},

		edit_collection() {
			this.show_collection_editor = true
			// this.hide_unit()
		},

		safari_search() {
			let o = {
				tool: 'safari',
				endpoint: 'https://montage.henry.k12.ga.us/SAFARI/api/imslti.php',
				search_params: {}
			}
			this.$store.dispatch('lti_launch', o)
		},

		up_one_level() {
			if (empty(this.unit_showing) || this.unit_showing == 0) {
				this.$router.push({ path: '/collections' })
			} else {
				this.$router.push({ path: sr('/collections/$1/$2', this.collection_id, 0) })
			}
		},
	}
}
</script>

<style lang="scss">
.k-collections-view-wrapper {
	margin-top:20px!important;

	.k-page-title {
		margin-bottom:0;
	}

	.k-safari_btn {
		height:36px;
		display:inline-block;
	    cursor: pointer;
	    background-color: #fff;
	    border-radius: 10px;
	    padding: 3px 16px;
	    vertical-align: middle;
		img {
			height: 30px;
		}
	}
}

.k-collection-view-collection-icon {
	height:70px;
	width:70px;
	border-radius:8px;
	margin-right:12px;
}

.k-collections-collection-description-wrapper {
	margin-top:20px;
}

.k-collections-collection-back-button {
	flex:1;
	padding-left:15px;
}

.k-collections-collection-description {
	background-color:#fff;
	color:#000;
	max-width:680px;
	//margin:20px auto 0 auto;
	margin:0 auto 0 auto;
	padding:4px 16px;
	border-radius:8px;
	font-size:16px;
}

.k-collections-units-outer-wrapper {
	display:flex;
	background-color:#fff;
	border-radius:10px;
	// padding:8px;
	max-width:680px;
	margin:20px auto 16px auto;
}

.k-collections-units-outer-wrapper--unit-showing {
	max-width:1000px;
}

.k-collections-units-list {
	flex:1 1 300px;
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
}

.k-collections-unit-content-wrapper {
	flex:1 1 700px;
	border-radius:0 12px 12px 0;
	background-color:#fff;
}

@media (max-width: 1200px) {
	.k-collections-units-outer-wrapper--unit-showing { max-width:900px; }
	.k-collections-units-list { flex: 1 1 270px; }
	.k-collections-unit-content-wrapper { flex: 1 1 670px; }
}

@media (max-width: 1100px) {
	.k-collections-units-outer-wrapper--unit-showing { max-width:800px; }
	.k-collections-units-list { flex: 1 0 240px; }
	.k-collections-unit-content-wrapper { flex: 1 1 560px; }
}

@media (max-width: 1024px) {
	.k-collections-units-outer-wrapper--unit-showing { max-width:700px; }
	.k-collections-units-list { flex: 1 0 210px; }
	.k-collections-unit-content-wrapper { flex: 1 1 490px; }
}

.k-collections-unit-list-item {

}

.k-collections-unit-list-item--showing {
	background-color:#fff;
}

.k-collections-unit-title {
	display:flex;
	align-items: flex-start;
	cursor:pointer;
	font-weight:bold;
	padding-left:4px;
	line-height:24px;
	padding-top:4px;
	padding-bottom:4px;

	.v-btn {
		align-self:flex-start;
		margin-top:-6px;
	}
	.fas {
		font-size:20px!important;
	}
}

.k-collections-unit-list-item-title { font-size:18px; }
@media (max-width: 1100px) {
	.k-collections-unit-list-item-title { font-size:16px; }
}
@media (max-width: 1024px) {
	.k-collections-unit-list-item-title { font-size:14px; }
}

.k-collections-unit-list-item-assignment-total {
	margin-right:8px;
	margin-left:4px;
	font-size:12px;
	font-weight:bold;
	background-color:$v-pink-accent-3;
	color:#fff;
	flex:0 0 20px;
	width:20px;
	height:20px;
	border-radius:20px;
	line-height:20px;
	text-align:center;
}

.k-collections-unit--first {
	border-radius:10px 0 0 0;
	padding-top:4px;
}

.k-collections-unit--last {
	flex:1 1 auto;
	border-radius:0 0 0 10px;
	padding-bottom:8px;
}

.k-collections-unit-list-item--showing.k-collections-unit--last {
}

@media (max-width: 1300px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: $screen-sm) {
}

</style>
