<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-wrapper">
	<div class="k-main-wrapper-bgd"></div>
	<v-app-bar app dense :hide-on-scroll="small_screen" class="k-app-toolbar">
		<a href="https://www.cps.edu/services-and-supports/special-education/ISBE-student-specific-corrective-action" target="_blank" class="k-toolbar__logo-img-holder">
			<img class="k-toolbar__logo-img1" src="./../../images/odlss_shapes.png">
			<img class="k-toolbar__logo-img2" src="./../../images/odlss_acronym.png">
			<img class="k-toolbar__logo-img3" src="./../../images/odlss_text.png">
		</a>
		<v-spacer></v-spacer>
		<div v-if="!small_screen" class="k-toolbar__username">{{user_info.email}}</div>
		<UserMenu/>

		<template v-slot:extension v-if="small_screen"><div class="k-top-nav">
			<!-- <v-btn class="k-main-view-btn" @click="go_to_route('welcome',$event)" fab small color="primary" :outlined="show_launchers||main_mode!='welcome'"><v-icon>fas fa-home</v-icon></v-btn> -->
			<v-btn class="k-main-view-btn" @click="go_to_route('collections', $event)" fab small color="primary" :outlined="show_launchers||(main_mode!='collections')"><v-icon>fas fa-chalkboard</v-icon></v-btn>
			<v-btn class="k-main-view-btn" @click="go_to_route('parent_center',$event)" fab small color="primary" :outlined="show_launchers||main_mode!='parent_center'"><v-icon>fas fa-users</v-icon></v-btn>
			<v-btn v-show="role=='teacher'||role=='staff'" class="k-main-view-btn" @click="go_to_route('pd',$event)" fab small color="primary" :outlined="show_launchers||main_mode!='pd'"><v-icon>fas fa-user-graduate</v-icon></v-btn>
			<!-- <v-btn v-show="role=='admin'" class="k-main-view-btn" @click="go_to_route('admin',$event)" fab small color="primary" :outlined="show_launchers||main_mode!='admin'"><v-icon>fas fa-cog</v-icon></v-btn> -->
			<Launchers :show_launchers="show_launchers" @hide_launchers="show_launchers=false"/>
			<v-btn class="k-main-view-btn" @click="show_launchers=!show_launchers" fab small color="primary" :outlined="!show_launchers"><v-icon>fas fa-bookmark</v-icon></v-btn>
			<v-btn class="k-main-view-btn" @click="show_help" fab small color="primary" :outlined="false"><v-icon>fas fa-question</v-icon></v-btn>
		</div></template>
	</v-app-bar>

	<div class="k-left-nav" v-if="!small_screen">
		<!-- <v-tooltip right><template v-slot:activator="{on}"><v-btn class="k-main-view-btn" v-on="on" @click="go_to_route('welcome',$event)" fab x-large color="primary" :outlined="show_launchers||main_mode!='welcome'"><v-icon>fas fa-home</v-icon></v-btn></template>Home</v-tooltip> -->
		<v-tooltip right><template v-slot:activator="{on}"><v-btn class="k-main-view-btn" v-on="on" @click="go_to_route('collections', $event)" fab x-large color="primary" :outlined="show_launchers||main_mode!='collections'"><v-icon>fas fa-chalkboard</v-icon></v-btn></template>Learning Resource Collections</v-tooltip>
		<v-tooltip right><template v-slot:activator="{on}"><v-btn class="k-main-view-btn" v-on="on" @click="go_to_route('parent_center',$event)" fab x-large color="primary" :outlined="show_launchers||main_mode!='parent_center'"><v-icon>fas fa-users</v-icon></v-btn></template>Parent Center</v-tooltip>
		<!-- <v-tooltip right><template v-slot:activator="{on}"><v-btn v-show="role=='teacher'||role=='staff'" class="k-main-view-btn" v-on="on" @click="go_to_route('pd',$event)" fab x-large color="primary" :outlined="show_launchers||main_mode!='pd'"><v-icon>fas fa-user-graduate</v-icon></v-btn></template>Professional Development</v-tooltip> -->
		<Launchers :show_launchers="show_launchers" @hide_launchers="show_launchers=false"/>
		<!-- <v-tooltip right><template v-slot:activator="{on}"><v-btn v-show="role=='admin'" class="k-main-view-btn" v-on="on" @click="go_to_route('admin',$event)" fab x-large color="primary" :outlined="show_launchers||main_mode!='admin'"><v-icon>fas fa-cog</v-icon></v-btn></template>Site Admin Tools</v-tooltip> -->
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn class="k-main-view-btn" v-on="on" @click="show_launchers=!show_launchers" fab x-large color="primary" :outlined="!show_launchers"><v-icon>fas fa-bookmark</v-icon></v-btn></template>Jump To...</v-tooltip>
		<v-tooltip right><template v-slot:activator="{on}"><v-btn class="k-main-view-btn" v-on="on" @click="show_help" fab x-large color="primary" :outlined="true"><v-icon>fas fa-question</v-icon></v-btn></template>Site Help</v-tooltip>
	</div>

	<!-- <transition name="k-fade"><keep-alive><router-view :key="router_key"></router-view></keep-alive></transition> -->
	<keep-alive><router-view :key="router_key"></router-view></keep-alive>

	<div class="k-copyright" @click="show_change_log">© 2021 Chicago Public Schools &nbsp; (v{{$store.state.app_version}})</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import Launchers from './Launchers'
import UserMenu from './UserMenu'

export default {
	components: { Launchers, UserMenu },
	data() { return {
		show_launchers: false,
	}},
	computed: {
		...mapState(['small_screen', 'user_info']),
		role() { return this.user_info.role },
		main_mode() {
			let path = this.$route.fullPath
			return path.replace(/^\/(\w+).*/, '$1')
		},
		router_key() {
			let path = this.$route.fullPath
			// by using this as the key, we preserve the right amount of "history" in our components...
			if (vapp.$route.name == 'assignments_collection') {
				return path.replace(/(\/assignments\/collection\/\d+).*/, '$1')
			} else {
				return path
			}
			// we use to always use fullPath, but that made it so that every time you viewed a new assignment, a new copy of AssignmentsMain was rendered
		},
	},
	created() {
		window.main_view = this
	},
	mounted() {
	},
	watch: {
		main_mode() {
			this.show_launchers = false
		}
	},
	methods: {
		go_to_route(new_route, event) {
			this.show_launchers = false

			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()

			new_route = '/' + new_route
			if (this.$route.path == new_route) return

			this.$router.push({ path: new_route })
		},

		go_to_classes($event) {
			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()

			// put go_to_classes fn in vapp, so it can be called from multiple places
			vapp.go_to_classes()
		},

		sign_out() {
			// for demo purposes we sign out by clearing password from local_storage and reloading
			U.local_storage_set('district_portal_login_password', '')
			window.location.reload()

			// this.$store.dispatch('sign_out')
		},

		show_change_log() {
			this.go_to_route('changelog')
		},

		show_help() {
			let msg = sr('For assistance using this site, please email <a href="mailto:$1">$1</a>.', this.$store.state.support_email)
			this.$alert(msg);
		},
	}
}
</script>

<style lang="scss">
// $toolbar-white-opacity:.95;
$toolbar-white-opacity:1;

.k-main-wrapper {
	.k-main-wrapper-bgd {
		position:fixed;
		z-index:-1;
		left:0;
		top:0;
		width:100vw;
		height:100vh;
		background:rgba(0, 0, 0, 0.65)!important;
	}

	margin-bottom:40px;
	margin-right:15px;

	.k-elevated {
		-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.k-app-toolbar {
		min-height:56px;
		opacity:0.9;
		background-color:rgba(255, 255, 255, $toolbar-white-opacity)!important;

		.k-toolbar__logo-img-holder {
			display:flex;
			align-items: center;
			padding-top:6px;
		}

		.k-toolbar__logo-img {
			height:40px;
			cursor:pointer;
		}

		.k-toolbar__logo-img1 {
			height:50px;
		}
		.k-toolbar__logo-img2 {
			height:35px;
			margin:0 12px 0 6px;
		}
		.k-toolbar__logo-img3 {
			height:40px;
		}

		.k-toolbar__username {
			margin-right:10px;
			margin-top:5px;
			font-family:$display-font;
			font-weight:900;
			font-size:1.4em;
			line-height:1.0em;
			text-align:center;
		}
	}

	.k-left-nav {
		position:fixed;
		z-index:2;
		left:20px;
		top:150px;

		.k-main-view-btn {
			display:block;
			width:100px;
			height:100px;
			margin:10px 0;
			background-color:#fff;

			.v-icon {
				font-size:48px;
			}
			border:3px solid $secondary-color!important;
		}

		.v-btn--outlined {
			border-color:#fff!important;
			opacity:0.7;
		}
	}

	.k-main-division-wrapper {
		margin-left:140px;
		margin-top:20px;
	}

	.k-page-wrapper {
		background-color:$page-background;
		padding:15px;
		max-width:1200px;
		margin:30px auto 0 auto;
		border-radius: 8px;
	}

	.k-page-title {
		font-size:27px;
		margin-bottom:20px;
		.k-page-title-btn {
			width:70px;
			height:70px;
			background-color:$secondary-color;
			color:$primary-color;
			border-radius:50%;
			margin-right:10px;
			font-size:40px;
			float:left;
		}
	}

	.k-main-collection {
		border-radius:8px;
		padding:10px;
		max-width:1000px;
		margin:30px auto 0 auto;
		background-color:$page-background;
	}

	.k-main-collection-header {
		font-size:18px;
		font-weight:bold;
		margin:5px 15px;
	}

	.k-main-collection-body {
		display:flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items:center;
		justify-content: flex-start;	// center
		margin:10px 10px;
		clear:both;
	}

	.k-main-collection-item {
		margin:12px;
		// border:3px solid $primary-color;
		padding:8px;
		font-size:16px;
		line-height:22px;
		text-align:center;
		vertical-align:middle;
		// background-color:$secondary-color;

		width:150px;
		height:150px;
		border-radius:25px;

		display:flex;
		justify-content: center;
		flex-direction: column;

		position:relative;
		cursor:pointer;
	}
}

.k-copyright {
	position:absolute;
	bottom:0;
	right:0;
	padding:10px;
	text-align:right;
	white-space:nowrap;
	font-size:.85em;
	color:#999;
	font-weight:bold;

	// cursor pointer for easter egg
	cursor:pointer;
}


.k-fade-enter-active {
	transition: opacity .25s;
}
.k-fade-enter, .k-fade-leave-active {
	opacity: 0;
}

@media (max-height: 800px) {
	.k-main-wrapper {
		.k-left-nav {
			top:65px;
		}
	}
}

// ipad
@media (max-width: 1024px) {
	.k-main-wrapper {
		.k-left-nav {
			left:15px;
			top:100px;

			.k-main-view-btn {
				width:70px;
				height:70px;
				.v-icon {
					font-size:32px;
				}
			}
		}

		.k-main-division-wrapper {
			margin-left:100px;
			margin-top:0px;
		}

		.k-page-wrapper {
			margin-top:15px;
		}

		.k-main-collection {
			margin-top:15px;
		}
	}
}

@media (max-width: $screen-sm) {
	.k-main-wrapper {
		margin-bottom:30px;
		margin-right:0px;

		.k-app-toolbar {
			min-height:auto;
			background:transparent!important;
			.v-toolbar__content {
				padding:0 5px 0 10px;
				background-color:rgba(255, 255, 255, $toolbar-white-opacity)!important;
			}

			.k-toolbar__logo-img {
				height:20px;
			}
		}

		.v-toolbar__extension {
			justify-content:center;
			background:rgba(0, 0, 0, 0.85);
		}

		.k-top-nav {
			.k-main-view-btn {
				background-color:#fff;
				margin:0 3px;

				border:2px solid $secondary-color!important;
			}

			.v-btn--outlined {
				border-color:#fff!important;
				opacity:0.7;
			}
		}

		.k-main-division-wrapper {
			margin-left:0px;
			margin-top:10px;
		}

		.k-copyright {
			padding:5px;
			font-size:.8em;
		}

		.k-page-wrapper {
			margin:20px auto 0 auto;
			border-radius: 0;
		}

		.k-page-title {
			font-size:20px;
			.k-page-title-btn {
				width:40px;
				height:40px;
				font-size:20px;
			}
		}

		.k-main-collection {
			border-radius:0px;
			margin:20px auto 0 auto;
		}

		.k-main-collection-header {
			font-size:16px;
			margin:0px 5px 5px 5px;
		}

		.k-main-collection-body {
			justify-content: space-around;
			margin:0;
			padding:5px 0;
		}

		.k-main-collection-item {
			margin:5px;
			padding:5px;
			font-size:13px;
			line-height:17px;

			width:105px;
			height:105px;
			border-radius:15px;
		}

	}

	// don't show editing btns when on small screens
	.k-edit-btn {
		display:none;
	}

}

</style>
