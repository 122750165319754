// vuex store for portal application
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// this imports the version number from package.json, so we can show the version number in the ui with $state.store.app_version; also need something in vue.config.js (PACKAGE_VERSION)
		// run the following to update the third number; use 'minor' to update the second number and 'major' to update the first number
		// npm --no-git-tag-version version patch; npm run build; npm run serve
		app_version: process.env.PACKAGE_VERSION || '0.0.0',

		login_error: null,
		user_info: {},
		small_screen: false,

		searchable_links: {
			top_hits: [],
			school_links: [],
			schoolwire_nav_links: [],
			employee_links: [],
			student_links: [],
			parent_links: [],
			community_links: [],
		},

		grades: [],
		subjects: {},

		search_grade_range: {low: -1, high: 13},

		all_collections: [],
		collection_update_trigger: 0,

		content_files: {
			upcoming_events: 'Events contents here',
			odlss_newsletters: 'Newsletters contents here',
			monthly_highlights: 'Monthly highlights contents here',
		},

		// these are used in App.vue / launch_sso_resource_link
		link_sso_patterns: {
			brainpop: [new RegExp('brainpop.com/')],
			achieve3000: [new RegExp('achieve3000.com/')],
			ixl: [new RegExp('ixl.com/')],
			mindfulpractices: [new RegExp('mindfulpractices.us/')],
		},

		all_assignments: [],
		// all_assignment_results stores a student's result records (or a teacher's simulated student result records for when they go to student view)
		// for a teacher, their class's assignment results will be stored as part of each assignment's record
		all_assignment_results: {},
		cola_unit_collections_showing: true,
		show_all_asns: false,

		student_sim_view: false,
		simulated_results: false,

		lti_sso_status: {},

		froala_key: '',

		support_email: 'odlssportalhelp@gmail.com',

		// "local_storage settings": set defaults here; lst_initialize is called on initialization; call lst_set to set new values, possibly in computed:
		// foo: {
		// 	get() { return this.$store.state.lst.foo },
		// 	set(val) { this.$store.commit('lst_set', ['foo', val]) }
		// },
		// @update:foo="(val)=>foo=val"
		lst: {
			resource_assignments: {},	// indexed by resource_id; possible values: 'pending', 'complete', '' (empty string)
		},
		lst_prefix: 'odlss_local_storage_setting_',
	},
	getters: {
	},
	mutations: {
		set(state, payload) {
			// this.$store.commit('set', ['key', val])
			// update state property 'key' to value 'val'
			if (payload.length == 2) {
				state[payload[0]] = payload[1]
				return
			}

			var o = payload[0]
			var key = payload[1]
			var val = payload[2]

			// anytime we alter an assignment or assignment result, trigger updates
			if (typeof o === 'object' && typeof o !== null && (o instanceof Assignment || o instanceof Assignment_Result)) {
				this.commit('trigger_collection_update')
			}

			// this.$store.commit('set', ['obj', 'key', val])
			// update property 'key' of 'o' to value 'val'
			if (typeof(o) == 'string') {
				if (state[o][key] == undefined) Vue.set(state[o], key, val)
				else state[o][key] = val
				return
			}

			// this.$store.commit('set', [obj, 'key', true])
			// this.$store.commit('set', [obj, ['level_1_key', 'level_2_key'], true])
			// this.$store.commit('set', [obj, 'PUSH', 1])	// push 1 onto obj, which must be an array in this case
			// update property of obj, **WHICH MUST BE PART OF STATE!**
			if (typeof(key) == 'string') {
				if (key == 'PUSH') {
					o.push(val)
				} else if (key == 'UNSHIFT') {
					o.unshift(val)
				} else if (key == 'SPLICE') {
					// if we got a fourth value in payload, add that value into the array; otherwise just take the val-th item out
					if (!empty(payload[3])) {
						o.splice(val, 1, payload[3])
					} else {
						o.splice(val, 1)
					}
				} else if (val == '*DELETE_FROM_STORE*') {
					// delete the val if it existed (if it didn't exist, we don't have to do anything)
					if (o[key] != undefined) Vue.delete(o, key)
				} else if (o[key] == undefined) {
					Vue.set(o, key, val)
				} else {
					o[key] = val
				}
			} else {
				for (var i = 0; i < key.length-1; ++i) {
					o = o[key[i]]
					if (empty(o)) {
						console.log('ERROR IN STORE.SET', key, val)
						return
					}
				}
				if (o[key[i]] == undefined) Vue.set(o, key[i], val)
				else o[key[i]] = val
			}

			// samples:
			// this.$store.commit('set', [this.exercise, ['temp', 'editing'], true])
			// this.$store.commit('set', [this.qstatus, 'started', true])
		},

		replace_in_array(state, payload) {
			// this.$store.commit('replace_in_array', [array, old_val, new_val])
			let arr = payload[0]

			// try to find the index of the old_val; caller can send either a value to look for directly, or a property and a value
			let i, new_val
			if (payload.length == 3) {
				let old_val = payload[1]
				new_val = payload[2]
				i = arr.findIndex(x=>x==old_val)
			} else {
				let prop = payload[1]
				let old_val = payload[2]
				new_val = payload[3]
				i = arr.findIndex(x=>x[prop]==old_val)
			}

			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1, new_val)
			} else {
				// else push
				arr.push(new_val)
			}
		},

		splice_from_array(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let old_val = payload[1]

			// try to find the index of the old_val
			let i = arr.findIndex(x=>x==old_val)
			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1)
			}
		},

		splice_from_array_by_index(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let i = payload[1]

			arr.splice(i, 1)
		},

		trigger_collection_update(state) {
			state.collection_update_trigger += 1
		},

		// fns to initialize and set local_storage settings
		lst_initialize(state) {
			for (let key in state.lst) {
				let val = U.local_storage_get(state.lst_prefix + key)
				if (!empty(val)) {
					state.lst[key] = val
				}
			}
		},

		// this.$store.commit('lst_set', ['mc_mode', 'bubbles'])
		lst_set(state, payload) {
			let key, val
			if (typeof(payload) == 'string') {
				// if a single string value is sent in, we just save in local_storage; presumably the changed value will have been already saved via set
				U.local_storage_set(state.lst_prefix + payload, state.lst[payload])
				return
			}

			if (Array.isArray(payload)) {
				key = payload[0]
				val = payload[1]
			} else {
				key = payload.key
				val = payload.val
			}

			// save in state
			state.lst[key] = val

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, val)
		},

		lst_clear(state, key) {
			U.local_storage_clear(state.lst_prefix + key)
		},

	},
	actions: {
		initialize_app({state, commit, dispatch}, payload) {
			// initialize local_storage settings
			commit('lst_initialize')

			return new Promise((resolve, reject)=>{
				// BYPASS INITIALIZATION
				if (false) {
					commit('set', ['user_info', new User_Info({
						user_id: 1,
						first_name: 'Pepper',
						last_name: 'Williams',
						email: 'pw@pw.com',
						system_role: 'admin',
						role: 'admin',
					})])
					resolve('main')
					return
				}

				U.ajax('initialize_app', payload, result=>{
					if (result.status != 'ok') {
						console.log('Error in initialization!')
						reject()
						return
					}
					console.log('Initialized!', result)

					// store files
					commit('set', ['content_files', result.content_files])

					// store grades and subjects
					commit('set', ['grades', result.grades])
					commit('set', ['subjects', result.subjects])

					// if we didn't receive user_info, the user is not already logged in...
					if (empty(result.user_info)) {
						commit('set', ['login_error', result.login_error])
						resolve('login')
						return
					}

					// else we received user_info.  if we have a chosen_role in local_storage, add it to user_info; if this isn't there, role will be set to system_role
					result.user_info.role = U.local_storage_get('district_portal_chosen_role_' + result.user_info.user_id, '')
					commit('set', ['user_info', new User_Info(result.user_info)])

					// process incoming collections
					// sort, using the same algorithm used in CollectionsList, then add a "color_index"
					result.all_collections.sort((a,b)=>{
						// sort by is_core_collection
						let ccd = U.is_core_collection(a.title) - U.is_core_collection(b.title)
						if (ccd != 0) return ccd

						// sort by grade_low
						let gd = U.grade_value(a.grade_low) - U.grade_value(b.grade_low)
						if (gd != 0) return gd

						// then by title
						if (a.title < b.title) return -1
						if (b.title < a.title) return 1
						return 0
					})

					// use this algorithm so that each collection title has a unique color across grades
					let titles = []
					for (let i = 0; i < result.all_collections.length; ++i) {
						let ci = titles.findIndex(x=>x==result.all_collections[i].title)
						if (ci == -1) {
							ci = titles.length
							titles.push(result.all_collections[i].title)
						}
						ci += 4
						result.all_collections[i].color_index = ci
					}

					// establish collection records
					for (let collection_data of result.all_collections) {
						let collection = new Collection(collection_data)
						// data has not been loaded for the collections at this point
						collection.data_loaded = false
						commit('set', [state.all_collections, 'PUSH', collection])
					}

					// determine safari grade range to use for search for this user (if grade_levels are specified)
					if (state.user_info.grade_levels.length > 0) {
						// for now, at least, we hard-code grade_levels as 1, 3, 6, or 9, so just map these on
						let gl_mappings = {
							'1': { grade_low: 'PK', grade_high: '2'},
							'3': { grade_low: '3', grade_high: '5'},
							'6': { grade_low: '6', grade_high: '8'},
							'9': { grade_low: '9', grade_high: '12'},
						}
						let search_grade_range = {low:13, high:-1}
						for (let grade_level of state.user_info.grade_levels) {
							let glm = gl_mappings[grade_level]
							if (U.grade_value(glm.grade_low) < search_grade_range.low) search_grade_range.low = U.grade_value(glm.grade_low)
							if (U.grade_value(glm.grade_high) > search_grade_range.high) search_grade_range.high = U.grade_value(glm.grade_high)
						}

						commit('set', ['search_grade_range', search_grade_range])
					}

					state.froala_key = result.froala_key

					resolve('main')
				});
			})
		},

		// we get the "shell" of all collections on load; here we get the full data for a collection
		get_collection({state, commit, dispatch}, collection_id) {
			let payload = {
				user_id: state.user_info.user_id,
				collection_id: collection_id,
			}
			return new Promise((resolve, reject)=>{
				// if we already have the collection loaded in memory, don't replace it
				let existing_collection = state.all_collections.find(o=>o.collection_id == collection_id)
				if (existing_collection && existing_collection.data_loaded) {
					resolve(true)
					return
				}

				U.loading_start()
				U.ajax('get_collection', payload, result=>{
					U.loading_stop()

					if (result.status == 'not_found') {
						resolve(false)
						return
					}

					if (result.status != 'ok') {
						console.log('Error retrieving resourcecollection')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// preserve value of color_index; set data_loaded to true
					result.collection.data_loaded = true
					result.collection.color_index = existing_collection.color_index
					let collection = new Collection(result.collection)

					// push or splice
					let index = state.all_collections.findIndex(x=>x.collection_id == collection.collection_id)
					if (index > -1) {
						commit('set', [state.all_collections, 'SPLICE', index, collection])
					} else {
						commit('set', [state.all_collections, 'PUSH', collection])
					}

					resolve(true)
				});
			})
		},

		save_collection({state, commit, dispatch}, collection) {
			let payload = {
				user_id: state.user_info.user_id,
				collection_data: JSON.stringify(collection.copy_for_save())
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_collection', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating collection')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					// trigger updates
					commit('trigger_collection_update')
					resolve(result)
				});
			})
		},

		delete_collection({state, commit, dispatch}, collection) {
			let payload = {
				user_id: state.user_info.user_id,
				collection_id: collection.collection_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_collection', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting collection')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// splice out of all_collections array
					let index = state.all_collections.findIndex(o=>o == collection)
					state.all_collections.splice(index, 1)

					resolve()
				});
			})
		},

		save_file({state, commit, dispatch}, payload) {
			// payload should include `filename` and `html`
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_file', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating file')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// put new html in content_files
					state.content_files[payload.filename] = payload.html

					resolve()
				});
			})
		},

		save_resource({state, commit, dispatch}, args) {
			let payload = args.resource.copy_for_save()

			payload.user_id = state.user_info.user_id

			// if (!empty(args.uploaded_file_data)) {
			// 	payload.uploaded_file_data = args.uploaded_file_data
			// }
			let override_options = null
			if (!empty(args.uploaded_file)) {
				// file upload
				if (payload.type == 'upload') {
					let fd = new FormData()
					fd.append('file', args.uploaded_file)
					for (let key in payload) {
						fd.append(key, payload[key])
					}
					payload = fd
					override_options = {contentType: false, processData: false}
				} else {
					// html, entered in the resource editor directly
					payload.html = args.uploaded_file
				}
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_resource', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating resource')
						vapp.ping()		// call ping to check if the session is expired
						reject(result.status)
						return
					}

					// resolve with the resource data
					resolve(result.resource)
				}, override_options);
			})
		},

		delete_resource({state, commit, dispatch}, resource) {
			let payload = {
				user_id: state.user_info.user_id,
				resource_id: resource.resource_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_resource', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting resource')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// caller is responsible for keeping track of the resource...
					resolve(resource.resource_id)
				});
			})
		},

		save_assignment({state, commit, dispatch}, incoming_asns) {
			let old_asn = incoming_asns[0]
			let new_asn = incoming_asns[1]

			// transfer some things from old_asn to new_asn
			if (!empty(old_asn)) {
				new_asn.assignment_id = old_asn.assignment_id
				new_asn.created_at = old_asn.created_at
				new_asn.creator = old_asn.creator
			}

			if (empty(new_asn.creator)) new_asn.creator = state.user_info.user_id

			// note that edited_date and created_at (if necessary) will be set to *NOW* by the service

			// payload starts with class members generated by copy_for_save
			let payload = new_asn.copy_for_save()

			// now add assignees, based on assigned_to
			payload.assignees = []
			for (let assignee of new_asn.assigned_to) {
				payload.assignees.push(assignee.copy_for_save())
			}
			// any assignees in old_asn that *aren't* in new_asn need to be removed; we send the assignment_student_mapping_id for those
			if (!empty(old_asn)) {
				for (let oa of old_asn.assigned_to) {
					if (new_asn.assigned_to.findIndex(x=>x.matches(oa)) == -1) {
						payload.assignees.push({assignment_student_mapping_id: oa.assignment_student_mapping_id})
					}
				}
			}
			// json encode assignees
			payload.assignees = JSON.stringify(payload.assignees)

			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_assignment', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating assignment')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// delete old_asn from all_assignments (if there) and add the incoming record, which will have assignment_id filled in if new, and may have updated dates
					let index = (empty(old_asn)) ? -1 : state.all_assignments.findIndex(o=>o == old_asn)
					if (index > -1) state.all_assignments.splice(index, 1)

					// send the new_asn resource in to process_assignment_records to be attached to the assignment
					// commit('process_assignment_records', result.assignments)

					// trigger updates
					commit('trigger_collection_update')

					// resolve with (possibly new) assignment_id
					resolve(result.assignments[0].assignment_id)
				});
			})
		},

		delete_assignment({state, commit, dispatch}, asn) {
			let payload = {
				user_id: state.user_info.user_id,
				assignment_id: asn.assignment_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_assignment', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting assignment')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// splice out of all_assignments array
					let index = state.all_assignments.findIndex(o=>o == asn)
					if (index > -1) state.all_assignments.splice(index, 1)
					// call process_assigment_records, to remove the assignment from collection units
					// commit('process_assignment_records', [])
					commit('trigger_collection_update')
					resolve()
				});
			})
		},

		get_resource_record({state, commit, dispatch}, payload) {
			// payload should include the resource_id being queried; this fn is mainly (or possibly exclusively) used for lti launches
			// if payload includes "get_lti_form:'yes'", we will retrieve the form to do the launch

			// we have to send the user's *email address* for this service
			payload.email = state.user_info.email

			// don't show loading indicator here, as we call the service on hover for resources
			// U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_resource_record', payload, result=>{
					// U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve data about the resource.')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		get_lti_sso_launch_form({state, commit, dispatch}, payload) {
			// payload should include the 'target' for sso: safari, brainpop, ixl, etc.

			// we have to send the user's *email address* for this service
			payload.email = state.user_info.email

			return new Promise((resolve, reject)=>{
				U.ajax('get_lti_sso_launch_form', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to sso to ' + payload.target + '.')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		get_lti_safari_search_form({state, commit, dispatch}, payload) {
			// payload can include search params; see safari_search in app

			// we have to send the user's *email address* for this service
			payload.email = state.user_info.email

			return new Promise((resolve, reject)=>{
				U.ajax('get_lti_safari_search_form', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to search safari.')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		lti_launch({state, commit, dispatch}, payload) {
			// NOT CURRENTLY USED; we use get_resource_record for launching resources via LTI
			// To do an LTI launch, we call a service to get the LTI form html along with the javascript to auto-submit the form,
			// then we open a new window and write the html/js
			payload.user_id = state.user_info.user_id
			U.loading_start()
			U.ajax('get_lti_1_launch_form', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to launch the resource.')
					reject()
					return
				}

				// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
				let w = window.open()
				w.document.write(result.lti_form)
			});
		},

		// this.$store.dispatch('sign_out')
		sign_out({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
			}
			U.ajax('sign_out', payload, result=>{
				// regardless of result, reload the page, which should show the google login button
				document.location.reload()
			});
		},

	}
})
