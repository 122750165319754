<!-- Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams -->
<template><div class="k-collection-editor-wrapper">
	<h3>Resource Collection title and description:</h3>
	<div class="k-collection-editor-top-data elevation-3">
		<div class="d-flex mb-4">
			<v-text-field background-color="#fff" style="font-weight:bold" dense hide-details outlined label="Title" v-model="title"></v-text-field>
			<v-select v-model="grade_low" :items="grades_low" @change="grade_low_changed" label="Grade Low" dense outlined background-color="#fff" hide-details class="k-collection-editor-grade-menu" :menu-props="{maxHeight:700}"></v-select>
			<v-select v-model="grade_high" :items="grades_high" @change="grade_high_changed" label="Grade High" dense outlined background-color="#fff" hide-details class="k-collection-editor-grade-menu" :menu-props="{maxHeight:700}"></v-select>
			<v-checkbox class="ml-4 pt-0 mt-2" :label="'Active'" v-model="active" hide-details></v-checkbox>
		</div>

		<froala id="froala_collection_description_editor" :config="editor_config('Description (optional)')" v-model="description"></froala>
	</div>

	<div class="k-collection-editor-units">
		<h3 class="ml-3">Resource Collection Units:</h3>
		<div class="d-flex flex-wrap justify-left">
			<div v-for="(unit, i) in units" class="k-collection-editor-unit elevation-2">
				<div class="d-flex align-center">
					<v-text-field background-color="#fff" class="mr-1" hide-details outlined label="Title" v-model="unit.title"></v-text-field>
					<div class="ml-2" style="margin-right:-3px">
						<v-btn icon x-small color="#666" @click="move_unit(unit,'up')"><v-icon>fas fa-arrow-circle-up</v-icon></v-btn>
						<v-btn icon x-small color="#666" @click="move_unit(unit,'down')"><v-icon>fas fa-arrow-circle-down</v-icon></v-btn><br>
						<v-btn icon x-small color="red lighten-2" @click="delete_unit(unit)"><v-icon>fas fa-times-circle</v-icon></v-btn>
					</div>
				</div>
			</div>
			<v-spacer/>

			<div class="mt-4 text-center" style="width:100%">
				<v-btn color="#666" dark width="300" @click="create_new_unit()">Create New Unit</v-btn>
			</div>
		</div>
	</div>

	<v-card-actions class="mt-4">
		<v-btn color="red" dark @click="delete_collection" class="mr-1"><v-icon small class="mr-2">fas fa-trash-alt</v-icon> Delete Resource Collection</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="cancel_editor" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		collection: { type: Object, required: true },
	},
	data() { return {
		title: this.collection.title,
		active: (this.collection.active == 'yes'),
		description: this.collection.description,
		grade_low: this.collection.grade_low,
		grade_high: this.collection.grade_high,
		units: [],	// copied below

		unit_deletion_confirmed: false,

		initial_values: null,
	}},
	computed: {
		...mapState(['user_info']),
		grades() {
			let arr = []
			for (let grade of this.$store.state.grades) {
				arr.push({ value: grade, text: U.grade_display_value(grade)})
			}
			return arr
		},
		grades_low() {
			return [{value:'', text:'GRADE RANGE START'}].concat(this.grades)
		},
		grades_high() {
			return [{value:'', text:'GRADE RANGE END'}].concat(this.grades)
		},
	},
	created() {
	},
	mounted() {
		JSON.stringify(this.collection.copy_for_save())

		// create a copy of the collection to get a copy of units
		let collection_copy = new Collection(this.collection)
		this.units = collection_copy.units
	},
	methods: {
		editor_config(text, inline) {
			return U.get_froala_config({
				placeholderText: text,
				toolbarInline: (inline === true),
			})
		},

		grade_low_changed() {
			if (empty(this.grade_high) || U.grade_value(this.grade_high) < U.grade_value(this.grade_low)) {
				this.grade_high = this.grade_low
			}
		},

		grade_high_changed() {
			if (empty(this.grade_low) || U.grade_value(this.grade_low) > U.grade_value(this.grade_high)) {
				this.grade_low = this.grade_high
			}
		},

		create_new_unit() {
			let u = new Collection_Unit()
			// remove duration value so label shows in input
			u.duration = ''
			this.units.push(u)
		},

		delete_unit(unit) {
			if (this.unit_deletion_confirmed) {
				this.units.splice(this.units.findIndex(x=>x==unit), 1)

			} else {
				this.$confirm({
					text: 'Are you sure you want to delete this unit? (Note: you will not be asked to confirm subsequent unit deletions.)',
					acceptText: 'Delete Unit',
				}).then(y => {
					this.units.splice(this.units.findIndex(x=>x==unit), 1)
					// don't ask again
					this.unit_deletion_confirmed = true
				}).catch(n=>{}).finally(f=>{});
			}
		},

		move_unit(unit, direction) {
			let i = this.units.findIndex(x=>x==unit)

			if (direction == 'up') {
				if (i > 0) {
					this.units.splice(i, 1)
					this.units.splice(i-1, 0, unit)
				}
			} else {
				if (i < this.units.length - 1) {
					this.units.splice(i, 1)
					this.units.splice(i+1, 0, unit)
				}
			}
		},

		save_edits() {
			// trim titles
			this.title = $.trim(this.title)
			for (let unit of this.units) {
				unit.title = $.trim(unit.title)
			}

			if (empty(this.title)) {
				this.$alert('The Resource Collection must have a title.')
				return
			}

			if (empty(this.grade_low) || empty(this.grade_high)) {
				this.$alert('You must set the start and end of the grade range (the minimum and maximum grade levels can be equal).')
				return
			}

			this.$store.commit('set', [this.collection, 'title', this.title])
			this.$store.commit('set', [this.collection, 'active', this.active ? 'yes' : 'no'])
			this.$store.commit('set', [this.collection, 'description', this.description])
			this.$store.commit('set', [this.collection, 'grade_low', this.grade_low])
			this.$store.commit('set', [this.collection, 'grade_high', this.grade_high])
			this.$store.commit('set', [this.collection, 'units', this.units])

			if (JSON.stringify(this.collection.copy_for_save()) != this.initial_values) {
				this.$store.dispatch('save_collection', this.collection).then((result)=>{
					console.log(result)
					let original_collection_id = this.collection.collection_id
					// set returned collection_id
					this.$store.commit('set', [this.collection, 'collection_id', result.collection_id])

					// set returned collection_unit_ids, in case one or more units is new
					for (let i = 0; i < this.collection.units.length; ++i) {
						this.$store.commit('set', [this.collection.units[i], 'collection_unit_id', result.collection_unit_ids[i] * 1])
					}

					// if collection_id was originally 0, have to refresh to the new course
					if (original_collection_id == 0) {
						let path = sr('/collections/$1/0', this.collection.collection_id)
						document.location.pathname = path
					} else {
						this.cancel_editor()
					}
				})
			} else {
				console.log('nothing changed, so not saving')
				this.cancel_editor()
			}
		},

		delete_collection() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this collection? This is not undoable.',
			    acceptText: 'Delete Collection',
				acceptColor: 'red',
				// dialogMaxWidth: 800
			}).then(y => {
				this.$store.dispatch('delete_collection', this.collection).then((result)=>{
					this.$router.push({ path: '/' })
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		cancel_editor() {
			this.$nextTick(()=>this.$emit('editor_cancel'))
		},

	}
}
</script>

<style lang="scss">
.k-collection-editor-wrapper {
	clear:both;
	margin:10px;
	padding-top:15px;
	font-size:16px;
	h3 { padding-left:0!important; }

	.k-collection-editor-top-data {
		background-color:$v-amber-lighten-5;	// :#f08c78;
		padding:15px;
		margin:15px 0;
		border-radius:4px;
	}

	.k-collection-editor-grade-menu {
		max-width:135px;
		margin-left:10px;
	}

	.k-collection-editor-units {
		margin-top:20px;
		background-color:#f8cdad;
		padding:10px 0px 15px 0;
		border-radius:8px;
	}

	.k-collection-editor-unit {
		padding:10px;
		border:4px solid transparent;
		width:calc(33% - 20px);
		margin:10px;
		border-radius:4px;
		background-color:$v-amber-lighten-5; 	// :#f8cdad;
	}
}

</style>
