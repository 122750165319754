<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable><v-card>
		<v-card-title style="border-bottom:1px solid #999"><b>Edit Resource</b></v-card-title>
		<v-card-text style="font-size:14px; color:#000;">
			<div class="d-flex mb-4 mt-4">
				<!-- <v-select v-model="resource_type" :items="resource_types" label="Resource Type" tabindex="1" outlined hide-details dense background-color="#fff" style="width:50px" @change="resource_type_selected"></v-select> -->
				<v-select v-model="provider" :items="providers" label="Provider" tabindex="-1" outlined hide-details dense background-color="#fff" style="width:50px" class="ml-0" @change="$emit('resource_changed')"></v-select>
				<v-text-field v-show="resource_type!='none'&&resource_type!='html'" hide-details outlined clearable :label="provider=='safari'?'Safari Permalink':'Resource URL'" v-model="url" background-color="#fff" dense tabindex="2" class="ml-2" @change="$emit('resource_changed')"></v-text-field>
				<v-btn v-show="true" @click="safari_search" class="ml-2" fab small color="light-blue" dark><v-icon>fas fa-search</v-icon></v-btn>
				<!-- <v-btn v-show="resource_type=='upload'" @click="upload_file" class="ml-2" fab small color="light-blue" dark><v-icon>fas fa-file-upload</v-icon></v-btn> -->
			</div>

			<v-textarea v-show="resource_type!='none'" class="mb-4" hide-details outlined label="Resource title" v-model="description" background-color="#fff" auto-grow dense rows="1" tabindex="3" @change="$emit('resource_changed')"></v-textarea>
			<div class="mb-1 ml-1"><b>Resource description (optional):</b></div>
			<froala v-show="resource_type!='none'" class="mb-4" id="froala_collection_description_editor" :config="editor_config('')" v-model="long_description" @change="$emit('resource_changed')"></froala>

			<div class="mb-4"><froala v-show="resource_type=='html'" id="froala_resource_html_editor" :config="editor_config()" v-model="resource_html"></froala></div>

			<div class="mb-4 d-flex justify-center flex-wrap" v-if="show_teacher_facing_control">
				<div class="mx-4 mb-4"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="teacher_facing" mandatory>
					<v-btn small light :value="false" style="letter-spacing:0">Student-Facing</v-btn>
					<v-btn small light :value="true" style="letter-spacing:0">Teacher-Facing</v-btn>
				</v-btn-toggle></div>

				<div v-if="!teacher_facing" class="mb-4">
					Target Students:
					<v-menu bottom left>
						<template v-slot:activator="{on}"><span v-on="on" class="ml-1">
							<v-btn small v-if="target_students=='all'" color="amber lighten-2">All Students</v-btn>
							<v-btn small v-if="target_students=='ese'" dark color="teal">ESE Students</v-btn>
							<v-btn small v-if="target_students=='adv'" dark color="purple">Advanced Learners</v-btn>
						</span></template>
						<v-list dense>
							<v-list-item @click="target_students='all'"><v-list-item-title>All Students</v-list-item-title></v-list-item>
							<v-list-item @click="target_students='ese'"><v-list-item-title>ESE Students</v-list-item-title></v-list-item>
							<v-list-item @click="target_students='adv'"><v-list-item-title>Advanced Learners</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</v-card-text>

		<v-card-actions class="mt-3" style="border-top:1px solid #999">
			<v-spacer/>
			<v-btn small class="mr-2" color="secondary" @click="$emit('edit_resource_cancel')" tabindex="6"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
			<v-btn v-if="resource_type!='none'" small color="primary" @click="save_resource" tabindex="5"><v-icon small class="mr-2">fas fa-check</v-icon>Save</v-btn>
		</v-card-actions>

		<SafariSearch v-if="show_safari_search" @dialog_done="show_safari_search=false"></SafariSearch>
		<!-- <SafariUpload v-if="show_safari_upload" :upload_type="safari_upload_type" @dialog_done="show_safari_upload=false"></SafariUpload> -->
	</v-card></v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import SafariSearch from './SafariSearch'
// import SafariUpload from './SafariUpload'

export default {
	components: { SafariSearch },	// , SafariUpload
	props: {
		resource: { type: Object, required: false, default() { return new Resource({resource_id:'new'}) } },
		show_save_buttons: { type: Boolean, required: false, default() { return true } },
		show_teacher_facing_control: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		dialog_open: true,
		resource_types: [
			{ value: 'video', text: 'Video' },
			{ value: 'interactive', text: 'Interactive/Simulation' },
			{ value: 'website', text: 'Website' },
			{ value: 'document', text: 'Document' },
			{ value: 'safari', text: 'SAFARI Resource' },
		],
		providers: [
			{ value: 'safari', text: 'SAFARI Resource' },
			{ value: 'achieve3000', text: 'Achieve3000' },
			{ value: 'brainpop', text: 'BrainPop' },
			//{ value: 'espark', text: 'eSpark' },
			//{ value: 'imaginelearning', text: 'Imagine Learning' },
			{ value: 'ixl', text: 'IXL' },
			{ value: 'mindfulpractices', text: 'Mindful Practices' },
			{ value: 'streamablelearning', text: 'Streamable Learning' },
			{ value: '', text: 'Other provider (no LTI)' },
		],
		// resource_type: this.resource.type,
		resource_type: 'website',	// for now we just assume a resource_type of 'website' for everything
		provider: this.resource.provider,
		description: this.resource.description,
		long_description: this.resource.long_description,
		url: this.resource.url,
		creator: this.resource.creator,
		teacher_facing: this.resource.teacher_facing,
		target_students: this.resource.target_students,
		resource_html: '',

		show_safari_search: false,
		show_safari_upload: false,
		safari_upload_type: 'link',	// 'link', 'file'
		uploaded_file: null,
		uploaded_file_name: '',
	}},
	computed: {
		...mapState(['small_screen']),
	},
	created() {
		// retrieve resource_html if we should already have it
		if (this.resource_type == 'html' && !empty(this.url)) {
			let html_url = '/user-files/' + this.url
			$.ajax({
				url: html_url,
				success: (html)=>{
					this.resource_html = html
				},
				cache: false,
			})
		}
	},
	mounted() {
	},
	methods: {
		editor_config(text) {
			return U.get_froala_config({
				placeholderText: text,
				zIndex: 2501,
			})
		},

		safari_search() {
			this.$alert('The ability to search SAFARI Montage for resources is coming in the future.')
			return

			U.loading_start()
			setTimeout(()=>{
				U.loading_stop()
				this.show_safari_search = true
			}, 250)
		},

		safari_upload() {
			U.loading_start()
			setTimeout(()=>{
				U.loading_stop()
				this.safari_upload_type = (this.resource_type == 'upload') ? 'file' : 'link'
				this.show_safari_upload = true
			}, 250)
		},

		upload_file() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Select the file you’d like to upload:</p>',
				promptType: 'file',
				acceptText: 'Choose File',
				cancelText: 'Cancel'
			}).then(file => {
				// console.log(file)
				// TODO: check for appropriate file types -- file.type
				// TODO: check for file size -- file.size
				// make sure file has an extension
				if (file.name.search(/.*(\.\w+)$/) == -1) {
					this.$alert('Uploaded files must have a valid file name extension, such as “.pdf” or “.jpg”')
					return
				}

				this.uploaded_file = file
				this.uploaded_file_name = file.name.replace(/.*(\.\w+)$/, U.new_uuid() + '$1')

				// set url to this.uploaded_file_name
				this.url = this.uploaded_file_name

				// if description is empty, set to the actual file name
				if (empty(this.description)) {
					this.description = file.name
				}

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		get_edited_resource() {
			// bundle data back up into a resource object and return it
			// convert from resource_type to type list from resources.js
			let type = this.resource_type
			if (type == 'google_drive' || type == 'google_assignment' || type == 'onedrive') type = 'document'
			let o = {
				saved: false,
				resource_id: this.resource.resource_id,
				type: type,
				provider: this.provider,
				description: this.description,
				long_description: this.long_description,
				url: this.url,
				creator: this.creator,
				teacher_facing: this.teacher_facing,
				target_students: this.target_students,
			}
			return new Resource(o)
		},

		resource_type_selected() {
			if (this.resource_type == 'upload') {
				this.upload_file()
			}
			this.$emit('resource_changed')
		},

		save_resource() {
			let r = this.get_edited_resource()
			let uploaded_file = null

			// if type is upload, we must have a resource guid in the url field
			if (r.type == 'upload') {
				if (!U.is_uuid(r.url.replace(/\.\w+$/, ''))) {
					this.$alert('For an Uploaded File resource, you must either choose a file to upload, or enter a previously-uploaded Resource File Identifier.')
					return
				}
				// if the user specified a file to be uploaded, include it in the resource save operation
				// (the user can alternatively specify an already-existing filename -- though this is risky and will probably be undocumented)
				if (r.url == this.uploaded_file_name) {
					uploaded_file = this.uploaded_file
				}

			} else if (r.type == 'html') {
				if (empty(this.resource_html)) {
					this.$alert('Please enter the text you’d like to save for this resource.')
					return
				}
				uploaded_file = this.resource_html
				// generate a filename for the html, if not already specified
				if (!U.is_uuid(r.url.replace(/\.\w+$/, ''))) {
					r.url = U.new_uuid() + '.html'
				}

			} else {
				if (empty(r.url)) {
					this.$alert('You must specify the URL for the resource.')
					return
				}
			}

			if (empty(r.description)) {
				this.$alert('Please enter a brief title for the resource.')
				return
			}

			this.$store.dispatch('save_resource', {resource: r, uploaded_file: uploaded_file}).then(()=>{
				// once resource is saved, emit edit_resource_save
				this.$emit('edit_resource_save', r)
			}).catch(error=>{
				console.log(error)
				this.$alert(sr('A problem occurred when attempting to save the resource. Message from server:<div class="mt-2">$1</div>', error))
			})
		},
	}
}
</script>

<style lang="scss">
</style>
