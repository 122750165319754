<!-- Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams -->
<template><div>
	<v-dialog v-model="dialog_open" max-width="900" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Edit Resource Collection Unit</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px; color:#000;">
				<v-row>
					<v-col cols="2">Unit Title:</v-col>
					<v-col cols="9"><v-text-field background-color="#fff" hide-details outlined label="" v-model="unit.title"></v-text-field></v-col>
				</v-row>

				<v-row>
					<v-col cols="2">Description:</v-col>
					<v-col cols="9"><froala id="froala_unit_text_editor" :config="editor_config('Unit Long Description')" v-model="unit.text"></froala></v-col>
				</v-row>

				<v-row>
					<v-col cols="2">Keyword(s) for SAFARI Search:</v-col>
					<v-col cols="9"><v-textarea background-color="#fff" placeholder="separate multiple keywords with spaces" hide-details outlined auto-grow rows="2" label="" v-model="unit.search_keywords"></v-textarea></v-col>
				</v-row>

				<div class="mt-6">
					<div class="d-flex align-center">
						<h3>Resources</h3>
						<v-btn class="ml-4" small text color="primary" @click="allow_resource_reordering=!allow_resource_reordering"><v-icon small class="mr-2">fas fa-arrows-alt-v</v-icon><span v-html="allow_resource_reordering?'Stop Reordering':'Reorder'"></span></v-btn>
					</div>

					<div class="mt-4">
						<CollectionResourceFolder
							:unit="unit"
							:folder_id="'top'"
							:enable_editing="true"
							:allow_resource_reordering="allow_resource_reordering"
							@editing_resource="this.editing_resource_set"
							@edit_resource_save="edit_resource_save"
							@save_unit_edits="save_edits(false)"
						/>
					</div>
				</div>
			</v-card-text>

			<v-card-actions v-visible="!editing_resource" class="mt-3">
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="cancel_editor" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
				<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionResourceFolder from './CollectionResourceFolder'

export default {
	components: { CollectionResourceFolder },
	props: {
		collection: { type: Object, required: true },
		original_unit: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		grade_low: this.collection.grade_low,
		grade_high: this.collection.grade_high,
		subject: this.collection.subject,

		// create a new unit to edit here; then we copy it into the original_unit when user saves
		unit: new Collection_Unit(this.original_unit),

		initial_values: JSON.stringify(this.original_unit.copy_for_save()),

		editing_resource: false,
		allow_resource_reordering: false,

		resource_import_rules: U.local_storage_get('district_portal_resource_import_rules', []),
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
		vapp.unit_editor = this
	},
	methods: {
		editor_config(text, inline) {
			return U.get_froala_config({
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: (inline === true),
				zIndex: 2501,
			})
		},
		unit_number_changed(unit) {
			// if user enters just a number, change to "Unit #"
			if (!isNaN(unit.title*1)) {
				unit.title = 'Unit ' + unit.title
			}
		},

		unit_has_changed() {
			return JSON.stringify(this.unit.copy_for_save()) != this.initial_values
		},

		editing_resource_set(status) {
			this.editing_resource = status
		},

		edit_resource_save(resource, edited_resource) {
			// transfer all properties from the edited_resource to the local resource and the one in the original unit
			let original_resource = this.original_unit.resources.find(o=>o.resource_id == resource.resource_id)
			for (let prop in edited_resource) {
				resource[prop] = edited_resource[prop]
				this.$store.commit('set', [original_resource, prop, edited_resource[prop]])
			}
		},

		save_edits(and_close) {
			// by default, we close after saving, but let caller specify not to
			if (and_close !== false) and_close = true

			return new Promise((resolve, reject)=>{
				if (!this.unit_has_changed()) {
					console.log('nothing changed (unit editor), so not saving')
					if (and_close) this.cancel_editor()
					resolve()
					return
				}

				this.unit.title = $.trim(this.unit.title)
				if (empty(this.unit.title)) {
					this.$alert('The unit must have a title.')
					reject()
					return
				}

				this.$store.commit('set', [this.original_unit, 'title', this.unit.title])
				this.$store.commit('set', [this.original_unit, 'text', this.unit.text])
				this.$store.commit('set', [this.original_unit, 'resources', this.unit.resources])
				this.$store.commit('set', [this.original_unit, 'resource_tree', this.unit.resource_tree])
				this.$store.commit('set', [this.original_unit, 'search_keywords', this.unit.search_keywords])

				this.$store.dispatch('save_collection', this.collection).then(()=>{
					if (and_close) this.cancel_editor()
					resolve()
				})
			})
		},

		cancel_editor() {
			this.$nextTick(()=>this.$emit('editor_cancel'))
		},
	}
}
</script>

<style lang="scss">

</style>
