<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<div class="k-search-wrapper" :class="!dense_search_bar?'k-search-wrapper--home':''"><div class="k-search-wrapper-inner">
		<v-text-field outlined rounded hide-details
			ref="main_search_box"
			background-color="#fff"
			:dense="dense_search_bar"
			@keypress="search_keypress"
			@focus="search_focused"
			@blur="search_blurred"
			@click:prepend-inner="do_search"
			placeholder="Search SAFARI Montage for learning resources by keyword"
			v-model="search_string"
			autocomplete="new-password"
		>
			<template v-slot:prepend-inner><v-icon>fas fa-search</v-icon></template>
			<template v-slot:append><img class="k-safari-search-icon" src="./../../images/launch_safari.png" @click="do_search"></template>
		</v-text-field>
	</div></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import safari_img from './../../images/launch_safari.png'
export default {
	components: {  },
	data() { return {
		search_string: '',		// this will mirror the value in the autocomplete input item itself...
	}},
	computed: {
		...mapState(['small_screen', 'user_info', 'search_grade_range']),

		dense_search_bar() {
			return this.$route.name != 'collections_list'
			// return false
		},
	},
	created() {
		vapp.site_search_component = this
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		search_focused() {
		},

		search_blurred() {
		},

		search_keypress(e) {
			// when the user presses a key, check for enter, and if clicked, run do_search
			// console.log('search_keypress', e.key)
			if (e.key == 'Enter') {
				this.do_search()
			}
		},

		do_search() {
			// allow for an empty string search -- just opens safari
			this.search_string = $.trim(this.search_string)

			let payload = {keywords: this.search_string}

			// safari maps "PK" on to -1 and "K" on to 0, so we can translate from grade indices to the values safari wants by subtracting 1 (PK = -1; K = 0; 1 = 1; etc.)
			payload.grade_start = this.search_grade_range.low - 1
			payload.grade_end = this.search_grade_range.high - 1

			vapp.search_safari(payload)
		},
	}
}
</script>

<style lang="scss">
.k-search-wrapper {
	// margin-left:140px;
	margin-top:40px;
	.k-search-wrapper-inner {
		max-width:800px;
		margin:0 auto;

		.theme--light.v-text-field--outlined fieldset {
			// border:3px solid $primary-color;
			border:0;
		}
		.v-text-field--outlined .v-label--active {
			transform:translateY(-20px) scale(0.75);
			padding:0 5px;
			// font-weight:bold;
		}
	}
}

.k-safari-search-icon {
	height:68px;
	position:absolute;
	margin-left:-60px;
	margin-top:-22px;
	cursor:pointer;
	clip-path: inset(15px 0);
	// border:1px solid black;
}

.k-search-wrapper--home {
	// margin-top:100px;
}

// ipad
@media (max-width: 1024px) {
	.k-search-wrapper {
		margin-top: 25px;
		margin-left:100px;
	}
}

@media (max-width: $screen-sm) {
	.k-search-wrapper {
		.v-text-field--rounded > .v-input__control > .v-input__slot {
			padding:0 10px!important;
		}

		margin-left:10px;
		margin-right:10px;
		margin-top:20px;
		.k-search-wrapper-inner {
			width:100%;
			margin:0 auto;

			.theme--light.v-text-field--outlined fieldset {
				border:3px solid $primary-color;
			}
			legend {
				width:0!important;
			}
			.v-text-field--outlined .v-label--active {
				display:none;
			}
		}
	}
}
</style>
