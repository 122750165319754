<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-division-wrapper k-main-collections">
	<router-view></router-view>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	data() { return {
	}},
	computed: {
		...mapState([]),
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-main-collections {
	position:relative;
}
</style>
