<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-division-wrapper"><div class="k-page-wrapper k-changelog">
	<h2 class="k-page-title">
		Site Development Status
	</h2>

	<p>This document will track updates to the ODLSS Portal site during the development phase of the project. Note that the items added to this document will tend to focus on updates that make visible changes to the site’s user interface; structural code changes that cause little or no changes to the UI will be noted but not elaborated.</p>

<h3>3/22 (v0.1.0)</h3>
<div class="k-markup">
- Initial prototype site live
</div>

</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		// community: { type: Object, required: true },
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
		$('.k-markup').each((i, el)=>{
			let html = ''
			let cur_level = 0
			let lines = $.trim($(el).text()).split('\n')
			for (let line of lines) {
				let text = line

				let level = 0
				if (line.search(/^(\t*)-\s+(.*)/) > -1) {
					text = RegExp.$2
					level = 1 + RegExp.$1.length
				}

				// some markup stuff
				text = text.replace(/\*\*\*(\S.*?)\*\*\*/g, '<b><i>$1</i></b>')
				text = text.replace(/\*\*(\S.*?)\*\*/g, '<b>$1</b>')
				text = text.replace(/\*(\S.*?)\*/g, '<i>$1</i>')

				// close open lists if necessary
				if (level < cur_level) {
					for (let i = cur_level; i > level; --i) {
						html += '</ul>'
					}

				// open lists if necessary
				} else if (level > cur_level) {
					for (let i = cur_level; i < level; ++i) {
						html += '<ul>'
					}
				}

				if (level > 0) {
					html += '<li>' + text + '</li>'
				} else {
					html += '<p>' + text + '</p>'
				}

				cur_level = level
			}

			for (let i = 0; i < cur_level; ++i) {
				html += '</ul>'
			}

			$(el).html(html)
		})
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-changelog {
	font-size:16px;
	max-width:850px!important;

	.k-markup {
		margin:0 0 30px 20px;
	}
}
</style>
