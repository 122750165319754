<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<v-menu bottom left><template v-slot:activator="{on}">
		<v-btn class="k-user-menu-show-large" v-on="on" color="#666" dark><v-icon small class="mr-2">fas fa-user</v-icon><span class="k-user-menu-role-display">{{role_display}}</span></v-btn>
		<v-btn class="k-user-menu-show-small" v-on="on" color="#666" icon dark><v-icon small>fas fa-user</v-icon></v-btn>
	</template>
		<v-list dense min-width="180">
			<v-list-item class="k-user-menu-show-small text-center"><v-list-item-title><b>{{user_info.first_name}} {{user_info.last_name}}</b></v-list-item-title></v-list-item>
			<v-divider class="k-user-menu-show-small"/>

			<v-list-item @click="show_user_admin_tool" v-if="system_role=='admin'"><v-list-item-icon><v-icon>fas fa-cog</v-icon></v-list-item-icon><v-list-item-title>User Admin Tool</v-list-item-title></v-list-item>

			<v-divider/>
			<v-list-item @click="sign_out"><v-list-item-icon><v-icon>fas fa-sign-out-alt</v-icon></v-list-item-icon><v-list-item-title>Sign Out</v-list-item-title></v-list-item>
		</v-list>
	</v-menu>
	
	<AdminUsers v-if="user_admin_tool_showing" @cancel_editor="hide_user_admin_tool" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import Launchers from './Launchers'
import AdminUsers from '../admin/AdminUsers'

export default {
	components: { AdminUsers },
	data() { return {
		user_admin_tool_showing: false
	}},
	computed: {
		...mapState(['user_info']),
		role() { return this.user_info.role },
		system_role() { return this.user_info.system_role },
		role_display() {
			if (this.role == 'student' || this.role == 'parent') return 'Parent / Student'
			return U.capitalize_word(this.role)
		}
	},
	created() {
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		show_user_admin_tool() {
			this.user_admin_tool_showing = true
		},

		hide_user_admin_tool() {
			this.user_admin_tool_showing = false
		},

		show_role(to_show) {
			// you can only adopt the admin role if your system_role is admin
			if (to_show == 'admin') {
				return this.system_role == 'admin'
			}

			if (to_show == 'staff') {
				return this.user_info.sis_identities.find(o=>{ return o.role == 'administrator' || o.role == 'teacher' }) || this.user_info.system_role == 'staff'
			}

			if (to_show == 'parent') {
				return this.user_info.sis_identities.find(o=>{ return o.role == 'guardian' }) || this.user_info.system_role == 'parent'
			}

			if (to_show == 'student') {
				return this.user_info.sis_identities.find(o=>{ return o.role == 'student' }) || this.user_info.system_role == 'student'
			}

			return false
		},

		change_role(new_role) {
			// for now we just set new role in local_storage and reload
			U.local_storage_set('district_portal_chosen_role_' + this.user_info.user_id, new_role)
			window.location.reload()
		},

		sign_out() {
			this.$store.dispatch('sign_out')
		},
	}
}
</script>

<style lang="scss">
.k-user-menu-show-large { display:flex; }
.k-user-menu-show-small { display:none; }

.v-btn.k-user-menu-show-large {
	margin-top:5px;
}

@media (max-width: $screen-sm) {
	.k-user-menu-show-large { display:none; }
	.k-user-menu-show-small { display:flex; }
}
</style>
