class Resource {
	constructor(data) {
		if (empty(data)) data = {}

		// // we're not actually using this "saved" parameter currently; but we won't take it out just yet.
		// if (!empty(data.saved)) {
		// 	sdp(this, data, 'saved', false)
		// } else {
		// 	// if we didn't get an explicit value for saved, assume the resource is pre-saved if we get a resource_id at the start
		// 	this.saved = (!empty(data.resource_id) && data.resource_id != 'new')
		// }

		// generate a new resource_id if needed
		if (data.resource_id == 'new') this.resource_id = U.new_uuid()
		else if (!empty(data.resource_id)) this.resource_id = data.resource_id
		else this.resource_id = U.new_uuid()

		// we may retrieve a resource's lti_form from the get_resource_record service
		sdp(this, data, 'lti_form', '')

		sdp(this, data, 'type', 'none', ['none', 'upload', 'html', 'website', 'document', 'video', 'interactive', 'safari', 'lti'])
		sdp(this, data, 'provider', '')

		sdp(this, data, 'url', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'long_description', '')
		sdp(this, data, 'lti_params', {})
		sdp(this, data, 'teacher_facing', false)
		sdp(this, data, 'target_students', 'all', ['all', 'ese', 'adv'])
		sdp(this, data, 'creator', 0)
		sdp(this, data, 'created_at', '')

		sdp(this, data, 'full_resource_data_loaded', false)
		sdp(this, data, 'quick_look_showing', false)
		sdp(this, data, 'search_match', false)
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		// delete o.saved
		delete o.editing
		delete o.quick_look_showing
		delete o.created_at
		delete o.lti_form
		delete o.full_resource_data_loaded
		delete o.search_match

		return o
	}
}
window.Resource = Resource

// standard sorting algorithm for resources: arr.sort(U.resources_sort)
U.resources_sort = function(a,b) {
	// teacher-facing on top
	if (a.teacher_facing && !b.teacher_facing) return -1
	if (b.teacher_facing && !a.teacher_facing) return 1

	// unit planning guides on top
	// if (a.description.search(/unit planning/i) > -1 && b.description.search(/unit planning/i) == -1) return -1
	// if (b.description.search(/unit planning/i) > -1 && a.description.search(/unit planning/i) == -1) return 1

	// for student resources, order by target_students: 'all', then 'ese', then 'adv'
	if (!a.teacher_facing && a.target_students != b.target_students) {
		if (a.target_students == 'all' && b.target_students != 'all') return -1
		if (b.target_students == 'all' && a.target_students != 'all') return 1
		// if we get here, neither a nor b is 'all', and they're different, so one must be ese and one must be adv

		if (a.target_students == 'adv') return -1
		else return 1
	}

	// if we get to here, order alphabetically by description
	if (a.description < b.description) return -1
	if (b.description < a.description) return 1
	return 0
}
