<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-launchers-wrapper-outer">
	<div v-show="show_launchers" class="k-launcher-bgd" @click.stop="$emit('hide_launchers')"></div>
	<transition name="launcher_flyout"><div v-show="show_launchers" class="k-launchers-wrapper elevation-3">
		<div class="text-center my-2"><b>Click to Launch a Resource Provider:</b></div>
		<div class="k-launchers-wrapper-inner">
			<div class="k-launcher" @click="launch('achieve3000')"><img style="border:1px solid #999;" src="./../../images/launch_achieve30002.png"></div>
			<div class="k-launcher">
				<v-menu auto offset-y><template v-slot:activator="{on}"><img v-on="on" style="border:1px solid #999;" src="./../../images/launch_brainpop3.png"></template>
					<v-list dense>
						<v-list-item @click="launch('brainpop', 'https://brainpop.com')"><v-list-item-title>BrainPOP (Grades 4-12)</v-list-item-title></v-list-item>
						<v-list-item @click="launch('brainpop', 'https://jr.brainpop.com')"><v-list-item-title>BrainPOP Jr. (Grades K-3)</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>
			<!-- educ8 aquired okin, launches the same way -->
			<div class="k-launcher" @click="launch('okin', 'https://i-educ8.com/')"><img style="border:1px solid #999;" src="./../../images/launch_educ8_hh.png"></div>

			<div class="k-launcher" @click="launch('ixl')"><img style="border:1px solid #999;" src="./../../images/launch_ixl2.png"></div>
			<div class="k-launcher" @click="launch('learnaroundtheworld', 'https://learnaroundtheworld.org/cps-odlss/events/')"><img style="border:1px solid #999;" src="./../../images/launch_latw.png"></div>
			<div class="k-launcher" @click="launch('mindfulpractices')"><img src="./../../images/launch_mindfulpractices4.png"></div>
			<div class="k-launcher" @click="launch('streamablelearning', 'https://www.streamablelearning.com/app/odlss/')"><img style="border:1px solid #999;" src="./../../images/launch_streamablelearning.png"></div>
		</div>
	</div></transition>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: {  },
	props: {
		show_launchers: { type: Boolean, required: true },
	},
	data() { return {
		search: '',
	}},
	computed: {
		...mapState([]),

	},
	created() {
	},
	mounted() {
	},
	methods: {
		launch(target, url) {
			if (empty(url)) {
				vapp.lti_sso(target, 'new_window')
			} else {
				vapp.lti_sso(target, x=>window.open(url, '_blank'))
			}
		}
	}
}
</script>

<style lang="scss">

.k-launcher {
	cursor:pointer;
}

.k-launchers-wrapper-outer {
	position:absolute;
	overflow:hidden;
	left:100px;
	// width:100%;
	margin-top:-44px;

	.k-launcher-bgd {
		position:fixed;
		z-index:-1;
		left:0;
		top:0;
		width:100vw;
		height:100vh;
		background:rgba(0, 0, 0, 0.85)!important;
	}

	.launcher_flyout-leave-active,
	.launcher_flyout-enter-active {
		transition: .3s;
	}
	.launcher_flyout-enter,
	.launcher_flyout-leave-to {
		opacity:0;
		transform: translate(-100%, 0);
	}

	.k-launchers-wrapper {
		align-items:center;
		padding:8px 15px 0 15px;
		background-color:#fff;
		border-radius:10px;
		margin-left:20px;
	}

	.k-launchers-wrapper-inner {
		display:flex;
		justify-content:flex-start;

		.k-launcher {
			padding: 10px;
			img {
				height:100px;
				max-width:120px;
				border-radius:8px;
			}
		}
	}
}

@media (max-width: $screen-sm) {
	.k-launchers-wrapper-outer {
		// position:absolute;
		// overflow:hidden;
		left:0;
		right:0;
		// width:100%;
		margin-top:10px;

		.k-launchers-wrapper {
			// display:flex;
			justify-content:center;
			align-items:center;
			flex-wrap:wrap;
			padding:0 10px;

			.k-launcher {
				padding: 8px;
				img {
					height:70px;
					max-width:70px;
				}
			}
		}
	}
}
</style>
