<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-elevated k-main-collection">
	<div class="k-main-collection-header">
		<h2 class="k-page-title">Site Admin Tools</h2>
	</div>
	<div class="k-main-collection-body">
		<div @click="go_to_admin_route('users')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-1"><div><b>Admin User Privileges</b></div><div><v-icon large color="#000">fas fa-user-cog</v-icon></div></div>
		<div @click="go_to_admin_route('jumpto')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-5"><div><b>Jump To Options</b></div><div><v-icon large color="#000">fas fa-bookmark</v-icon></div></div>
		<div @click="go_to_admin_route('search')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-6"><div><b>Site Search</b></div><div><v-icon large color="#000">fas fa-search</v-icon></div></div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
	},
	methods: {
		go_to_admin_route(which) {
			this.$router.push({ path: '/admin/' + which })
		},
	}
}
</script>

<style lang="scss">
</style>
