<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-container class="k-login-container" fill-height>
		<v-spacer/>
		<div class="k-login-wrapper">
			<!-- <img class="k-login__logo-img" src="./../../images/HenryConnects_Main_LOGO.svg"> -->
			<!-- <div>Office of Diverse Learner Supports and Services (ODLSS)</div> -->
			<div @click="go_to_route('welcome',$event)" class="k-login__logo-img-holder">
				<img class="k-login__logo-img1" src="./../../images/odlss_shapes.png">
				<img class="k-login__logo-img2" src="./../../images/odlss_acronym.png">
				<img class="k-login__logo-img3" src="./../../images/odlss_text.png">
			</div>
			<div v-if="friendly_login_error!=null" class="k-login-error" v-html="friendly_login_error"></div>
			<v-text-field @keyup="keyup" ref="email_field" class="mt-3" background-color="#eee" outlined hide-details prepend-inner-icon="fa fa-envelope" label="Email Address" v-model="email"></v-text-field>
			<v-text-field @keyup="keyup" class="mt-4" background-color="#eee" outlined password hide-details prepend-inner-icon="fa fa-key" :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password" label="Password" v-model="password"></v-text-field>
			<div class="d-flex mt-4">
				<v-btn fab x-small color="#666" dark @click="show_help"><v-icon small>fas fa-question</v-icon></v-btn>
				<v-spacer />
				<v-btn @click="sign_in" color="primary">Sign In</v-btn>
			</div>
		</div>
		<v-spacer/>
		<div class="k-copyright" @click="cycle_bgd_image">© 2021 Chicago Public Schools / <a href="https://www.cps.edu/about/departments/diverse-learner-supports-and-services/" target="odlss">Office of Diverse Learner Supports and Services</a> &nbsp; (v{{$store.state.app_version}})</div>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	data() { return {
		email: '',
		password: '',
		show_password: false,
	}},
	computed: {
		...mapState(['login_error']),
		friendly_login_error() {
			if (this.login_error == 'password_fail') {
				return 'Your email address and/or password could not be validated.'
			} else if (this.login_error == 'user_fail') {
				return 'Your email address could not be verified.'
			} else {
				return this.login_error
			}
		},
	},
	created() {
	},
	mounted() {
		this.$nextTick(()=>{
			this.email = U.local_storage_get('district_portal_login_email', '')
			$(this.$refs.email_field.$el).find('input').focus()
		})
	},
	methods: {
		cycle_bgd_image() {
			vapp.show_bgd_image(-1)
		},

		keyup(event) {
			if (event.code == 'Enter' || event.keyCode == 13) this.sign_in()
		},

		sign_in() {
			this.email = $.trim(this.email)
			this.password = $.trim(this.password)

			if (empty(this.email)) {
				this.$alert('Please enter your email address.')
				return
			}

			if (empty(this.password)) {
				this.$alert('Please enter your password.')
				return
			}

			// shortcut: if email has no @, append @cps.edu
			if (this.email.indexOf('@') == -1) {
				this.email += '@cps.edu'
			}

			// READY TO SIGN IN...

			// store email in local storage
			U.local_storage_set('district_portal_login_email', this.email)
			vapp.initialize_app({
				email: this.email,
				password: this.password,
			})
		},

		show_help() {
			let msg = sr('For assistance using this site, including help signing in, please email <a href="mailto:$1">$1</a>.', this.$store.state.support_email)
			this.$alert(msg);
		},
	},
}
</script>

<style lang="scss">
.k-login-container {
	// background-color:$primary-color;
	max-width:100vw;
	width:100vw;
}

.k-login-wrapper {
	opacity:0.9;
	border-radius:8px;
	background:rgba(255, 255, 255, 1);
	padding:10px;

	.k-login__logo-img {
		width:350px;
	}
}

.k-login-error {
	background-color:$v-red-lighten-4;
	color:$v-red-darken-4;
	border-radius:5px;
	padding:10px;
	margin: 10px 0 20px 0;
	max-width: 500px;
	font-size: 15px;
}

.k-login__logo-img-holder {
	display:flex;
	align-items: center;
	justify-content: center;
	margin:4px 8px;
}

.k-login__logo-img1 {
	height:35px;
}
.k-login__logo-img2 {
	height:26px;
	margin:0 10px 0 5px;
}
.k-login__logo-img3 {
	height:30px;
}

@media (max-width: $screen-sm) {
	.k-login-wrapper {
		// border-radius:0px;
		// width:100%;
		// background:rgba(255, 255, 255, 0.9);
		// padding:10px;

		.k-login__logo-img {
			max-width:calc(100vw - 40px);
		}
	}
}
</style>
