<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-division-wrapper">
	<div class="k-elevated k-main-collection k-parent-center" style="max-width:1100px">
		<h2 class="ml-3"><v-icon color="#fff">fas fa-users</v-icon>Parent Center</h2>

		<div class="d-flex ma-3 align-start">
			<div class="k-pc-collection elevation-3 mt-3 mr-2 text-center" style="flex:1 1 70%">
			<!-- <div class="k-pc-collection elevation-3 mt-3 text-center" style="width:620px; margin-left:auto; margin-right:auto;"> -->
				<h3>Training Resources</h3>

				<div class="d-flex justify-center mt-3">
					<div class="mx-2">
						<v-menu offset-y><template v-slot:activator="{on}"><img v-on="on" class="k-pc-training-img" style="border:1px solid #999;" src="./../../images/launch_achieve30002.png"></template>
							<v-list dense>
								<v-list-item><v-list-item-title class="text-center"><b>Training links for Achieve 3000</b></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://a3klhomeres.achieve3000.com/hc/en-us" target="_blank">Achieve3000 Home Resources</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=V4ExzDrxmgw" target="_blank">How Lexile & Quantile Measures Help Support Learning at Home</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://metametricsinc.com/parents-and-students/lexile-for-parents-and-students/lexile-for-reading/" target="_blank">Information on Lexile level</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.keaauhs.com/uploads/4/2/1/6/42168743/levelset_parentguide_homeadministration.pdf" target="_blank">LevelSet Assessment Guide for Parents</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://players.brightcove.net/1799386176001/default_default/index.html?videoId=6177075362001" target="_blank">LevelSet Assessment Video for Students</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://youtu.be/Ur9kNUX64l4" target="_blank">January 2024 Parent Webinar</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://helpcenter.achieve3000.com/hc/en-us/articles/1500002782261-Student-Dashboard-Navigation" target="_blank">Student Dashboard Navigation</a></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>

					<div class="mx-2">
						<v-menu offset-y><template v-slot:activator="{on}"><img v-on="on" class="k-pc-training-img" style="border:1px solid #999;" src="./../../images/launch_brainpop3.png"></template>
							<v-list dense>
								<v-list-item><v-list-item-title class="text-center"><b>Training links for BrainPOP</b></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=8KEWHiKLWD4&t=2s" target="_blank">How to Use BrainPOP at Home</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://educators.brainpop.com/learning-activities-support-resources/" target="_blank">Learning Activities Information and Support</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://educators.brainpop.com/movie-support-resources/" target="_blank">Movie Teaching Resources</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://educators.brainpop.com/school-home-connection-resources/" target="_blank">Planning with BrainPOP Topics</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://drive.google.com/file/d/17AdflCIBrCdfwyVxxBOEun3Evqt19U2e/view?usp=sharing" target="_blank">January 2024 Parent Webinar</a></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>

					<!-- educ8 aquired okin, launches the same way -->
					<div class="mx-2">
						<v-menu offset-y><template v-slot:activator="{on}"><img v-on="on" class="k-pc-training-img" style="border:1px solid #999;" src="./../../images/launch_educ8_hh.png"></template>
							<v-list dense>
								<v-list-item><v-list-item-title class="text-center"><b>Training links for educ8 Homework Hotline</b></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://player.hourone.ai/e8805e5f869d45f383c352c6a0c57d00" target="_blank">Introduction to educ8 (English)</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://player.hourone.ai/fd4fbf09326b4a3e80205431d36ee76a" target="_blank">Introduction to educ8 (Spanish)</a></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>


					<div class="mx-2">
						<v-menu offset-y><template v-slot:activator="{on}"><img v-on="on" class="k-pc-training-img" style="border:1px solid #999;" src="./../../images/launch_ixl2.png"></template>
							<v-list dense>
								<v-list-item><v-list-item-title class="text-center"><b>Training links for IXL</b></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.ixl.com/inspiration/learninghub" target="_blank">Family Learning Hub</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://blog.ixl.com/category/at-home/" target="_blank">IXL Official Blog</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=fy6wrmSE-Ek" target="_blank">IXL Parent Guide video (con subtítulos en Español)</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/playlist?list=PLa8fn4yxivymQ5vfaPoXSBKxUuA9TutgB" target="_blank">Parent Training Video Channel</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=Um185fKjrTg" target="_blank">January 2024 Parent Webinar</a></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>

					<div class="mx-2">
						<v-menu offset-y><template v-slot:activator="{on}"><img v-on="on" class="k-pc-training-img" style="border:1px solid #999;" src="./../../images/launch_latw.png"></template>
							<v-list dense>
								<v-list-item><v-list-item-title class="text-center"><b>Training links for Learn About the World</b></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=cbao2gx0peI" target="_blank">LATW Instructions Guide</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=LxhCoogsPiI&feature=youtu.be" target="_blank">Learn Around The World Introduction Video</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://youtu.be/SF7A0aFQcg4" target="_blank">January 2024 Parent Webinar</a></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>

					<div class="mx-2">
						<v-menu offset-y><template v-slot:activator="{on}"><img v-on="on" class="k-pc-training-img" src="./../../images/launch_mindfulpractices4.png"></template>
							<v-list dense>
								<v-list-item><v-list-item-title class="text-center"><b>Training links for Mindful Practices</b></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=whxQCQNiixM" target="_blank">Five To Thrive: Overview</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=TTrwRoEo9Ps" target="_blank">Five To Thrive: Overview (Spanish)</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://mindfulpractices.us/family-sel-resources/" target="_blank">SEL at Home</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://youtu.be/UGfI1Hu53VQ" target="_blank">January 2024 Parent Webinar</a></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>

					<div class="mx-2">
						<v-menu offset-y><template v-slot:activator="{on}"><img v-on="on" class="k-pc-training-img" style="border:1px solid #999;" src="./../../images/launch_streamablelearning.png"></template>
							<v-list dense>
								<v-list-item><v-list-item-title class="text-center"><b>Training links for Streamable Learning</b></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://vimeo.com/868528409?share=copy" target="_blank">January 2024 Parent Webinar</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://share.vidyard.com/watch/xFHEku7CwJpuKZGP5TNr1L" target="_blank">SL Instructions Guide</a></v-list-item-title></v-list-item>
								<v-list-item><v-list-item-title><a href="https://www.youtube.com/watch?v=iwcGgRYamDE" target="_blank">Streamable Learning Introduction Video</a></v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>
				</div>
			</div>

			<div class="k-pc-collection elevation-3 mt-3 ml-2" style="flex:1 1 30%">
				<h3>Monthly Newsletters</h3>
				<div class="fr-view" v-html="content_file_html('odlss_newsletters')"></div>
				<div v-if="is_admin" class="mt-2 text-right">
					<v-btn small text color="secondary" @click="edit_file('odlss_newsletters')"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Newsletters</v-btn>
					<!-- <v-btn small color="#ccc"><v-icon small class="mr-2">fas fa-archive</v-icon>Show More</v-btn> -->
				</div>
			</div>
		</div>

		<div class="d-flex ma-3 align-start">
			<div class="k-pc-collection elevation-3 mt-3 mr-2" style="flex:1 1 50%">
				<h3>Upcoming Events</h3>
				<div class="fr-view" v-html="content_file_html('upcoming_events')"></div>
				<div v-if="is_admin" class="mt-2 text-right">
					<v-btn small text color="secondary" @click="edit_file('upcoming_events')"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Events</v-btn>
					<!-- <v-btn small color="#ccc"><v-icon small class="mr-2">fas fa-archive</v-icon>Show Archived Events</v-btn> -->
				</div>
			</div>

			<div class="k-pc-collection elevation-3 mt-3 ml-2" style="flex:1 1 50%">
				<h3>Monthly Highlights</h3>
				<div class="fr-view" v-html="content_file_html('monthly_highlights')"></div>
				<div v-if="is_admin" class="mt-2 text-right">
					<v-btn small text color="secondary" @click="edit_file('monthly_highlights')"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Monthly Highlights</v-btn>
					<!-- <v-btn small color="#ccc"><v-icon small class="mr-2">fas fa-archive</v-icon>Show More</v-btn> -->
				</div>
			</div>
		</div>
	</div>

	<EditFile v-if="editing_filename" :filename="editing_filename" :file_title="editing_file_title" :original_contents="editing_original_contents" @cancel="editing_filename=''" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import EditFile from '../resources/EditFile'

export default {
	components: { EditFile },
	data() { return {
		editing_filename: '',
		editing_file_title: '',
		editing_original_contents: '',
	}},
	computed: {
		...mapState(['small_screen', 'user_info', 'content_files', 'link_sso_patterns']),
		is_admin() {
			return this.user_info.role == 'admin'
		},
	},
	created() {
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		content_file_html(filename) {
			let html = this.content_files[filename]

			// replace content links with sso links
			return html.replace(/(<a [^>]*?href=")(.*?)("[^>]*?>)/g, ($0, $1, $2, $3) => {
				let start = $1
				let url = $2
				let end = $3

				// if the url matches one of the patterns in link_sso_patterns, replace with an sso link
				for (let provider in this.link_sso_patterns) {
					for (let re of this.link_sso_patterns[provider]) {
						if (url.search(re) > -1) {
							return sr("<a href=\"javascript:vapp.launch_sso_resource_link('$1','$2')\">", provider, url)
						}
					}
				}

				// if not found, don't change anything
				return start + url + end
			})

		},

		edit_file(filename) {
			this.editing_filename = filename

			if (filename == 'upcoming_events') this.editing_file_title = 'Upcoming Events'
			else if (filename == 'odlss_newsletters') this.editing_file_title = 'ODLSS Newsletters'
			else this.editing_file_title = 'Monthly Highlights'

			this.editing_original_contents = this.content_files[filename]
		}
	}
}
</script>

<style lang="scss">
.k-pc-collection {
	background-color:#fff;
	border-radius:10px;
	padding:12px;
	font-size:16px;
	h3 {
		margin-bottom:8px;
	}
}

.k-pc-training-img {
	width:80px;
	border-radius:5px;
	cursor:pointer;
}

.k-parent-center {
	h2 {
		.v-icon {
			background-color:$primary-color;
			width:44px;
			height:44px;
			border-radius:44px;
			margin-right:12px;
		}
	}

	.fr-view {
		font-size:15px;
		line-height:20px;

		td {
			border:0!important;
		}
	}
}
</style>
