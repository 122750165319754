import Vue from 'vue'
// Require Froala Editor js file.
import 'froala-editor/js/froala_editor.pkgd.min.js'

// require packages
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'

// Require Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/themes/dark.min.css'

// require package css files
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/draggable.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/css/plugins/help.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/video.min.css'

// Import and use Vue Froala lib
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

import FroalaEditor from 'froala-editor';
window.FroalaEditor = FroalaEditor


U.get_froala_config = function(params) {
	// params could include, e.g.:
	// placeholderText
	// heightMin
	// heightMax
	// toolbarInline: true,
	if (empty(params)) params = {}

	let config = {
		key: vapp.$store.state.froala_key,
		placeholderText: '',
		charCounterCount: true,
		attribution: false,
		quickInsertEnabled: true,
		linkAlwaysBlank: true,
		theme: 'light',
		paragraphFormat: {
			N: 'Normal',
		    H1: 'Heading 1',
		    H2: 'Heading 2',
		    H3: 'Heading 3',
			BLOCKQUOTE: 'Block Quote',
		    PRE: 'Code',
		},
		paragraphFormatSelection: true,
		htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap',
			'onclick',
		],
		toolbarButtons: {
			moreRich: {buttons: ['bold', 'italic', 'insertLink', 'insertImage', 'insertVideo', 'emoticons', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align', 'formatOL', 'formatUL'], buttonsVisible: 6},	// , 'quote', 'outdent', 'indent'
			moreMisc: {buttons: ['paragraphFormat', 'insertTable', 'specialCharacters', 'clearFormatting', 'html', 'fullscreen'], buttonsVisible: 0, align:'right'}
		},
		imageEditButtons: ['imageDisplay', 'imageAlign', 'imageStyle', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAlt', 'imageSize', 'imageReplace', 'imageRemove'],	// , 'imageCaption' doesn't seem to work consistently
		imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
		linkInsertButtons: ['linkBack'],
		videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
		videoEditButtons: ['videoDisplay', 'videoAlign', 'videoSize', 'videoReplace', 'videoRemove'],

		imageDefaultWidth: 0,			// when first inserted, don't explicitly set the image's size
		imageResizeWithPercent: true,	// when you resize, specify size with percent instead of pixels
		imageRoundPercent: true,		// round to integer when resizing
		imageDefaultDisplay: 'block',
		imageDefaultAlign: 'center',

		// image uploading
		imageUploadURL: '/src/ajax.php',
	    imageUploadMethod: 'POST',
	    imageMaxSize: 1 * 1024 * 1024,	// 1 MB
	    imageUploadParams: {
			service_name: 'froala_file_upload',
	        user_id: vapp.user_info.user_id,
	    },
		imageUploadMethod: 'POST',
		imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'svg+xml', 'svg'],

		events: {
			'paste.beforeCleanup': function (clipboard_html) {
				// copied in from sparkl teacherapp. this is the editor instance.

				// try to preserve bold and italics
				let x = $(sr('<div>$1</div>', clipboard_html))
				x.find('*').each(function() {
					let el = $(this)

					// add strong tags for font-weight bold and > 400
					let fw = el.css('font-weight')
					if (fw == 'bold' || fw*1 > 400) {
						if (this.tagName != 'STRONG') {
							// console.log('found bold: ' + el.html())
							el.wrapInner('<strong></strong>')
						}
					}

					// add em tags for font-style italic
					let fs = el.css('font-style')
					if (fs == 'italic') {
						if (this.tagName != 'EM') {
							// console.log('found italic: ' + el.html())
							el.wrapInner('<em></em>')
						}
					}

					if (this.tagName == 'B') {
						el.replaceWith('<strong>' + el.html() + '</strong>')
					}
					if (this.tagName == 'I') {
						el.replaceWith('<em>' + el.html() + '</em>')
					}

					// remove style and class attributes
					el.removeAttr('style')
					el.removeAttr('class')
				})

				// remove these tags altogether
				x.find('base,head,link,meta,style,title,area,map,script,canvas,noscript,del,ins').remove()

				// these too
				x.find('option,datalist,fieldset,meter,optgroup,option,output,progress,select,textarea').remove()

				// these too
				x.find('iframe,video,audio,track,embed,object,param,picture,source').remove()

				// remove all inputs (remember that if this is an "internal paste" with queries, we won't be here)
				x.find('input').remove()

				// for these tags, extract everything in them and put them directly in the dom
				x.find('body,address,article,aside,footer,header,main,nav,section').replaceWith(function() {
					return $(this).html()
				})

				// these too
				x.find('button,label,legend').replaceWith(function() {
					return $(this).html()
				})

				let s = x.html()

				// preserve just the tags we want to save, and remove any attributes from non-images
				s = s.replace(/<((\/)?(em|strong|sub|sup))\b.*?>/gi, 'ZZZLTZZZ$1ZZZGTZZZ')
				s = s.replace(/<((\/)?img\b.*?)>/gi, 'ZZZLTZZZ$1ZZZGTZZZ')
				// console.log(s)

				// insert p tags at the ends of block-level things; this results in line breaks where we want them
				s = s.replace(/(\s*<\/(p|div|li|tr|figcaption|figure|pre|blockquote).*?>\s*)+/ig, 'ZZZLTZZZpZZZGTZZZ')

				// strip all other tags
				s = s.replace(/<(\/?)[a-z].*?>/ig, '')

				// put back the <'s we preserved
				s = s.replace(/ZZZLTZZZ/g, '<')
				s = s.replace(/ZZZGTZZZ/g, '>')

				// consolidate multiple ps
				s = s.replace(/(<p>)+/g, '<p>')

				return s
			},
		}
	}

	return $.extend(config, params)
}
