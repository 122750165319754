<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-app v-show="app_mode!='uninitialized'">
	<img id="k-bgd-img" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="">
	<v-main>
		<LoginView ref="login_view" v-if="app_mode=='login'"/>
		<MainView ref="main_view" v-if="app_mode=='main'"/>
	</v-main>
	<div style="position:absolute;left:-500px; bottom:100px; width: 200px; height:200px; overflow:hidden" id="sso_iframe_holder"></div>
</v-app>
</template>

<script>
import LoginView from './components/login/LoginView'
import MainView from './components/main/MainView'
import { mapState, mapGetters } from 'vuex'

import ela_subject_img from './images/ela.png'
import math_subject_img from './images/math.png'
import languages_subject_img from './images/languages.png'
import sel_subject_img from './images/sel.png'
import science_subject_img from './images/science.png'
import socialscience_subject_img from './images/socialscience.png'
import parent_resources_img from './images/parent_resources.png'
import misc_subject_img from './images/miscsubject.png'

export default {
	name: 'App',
	components: { LoginView, MainView },
	data() { return {
		app_mode: 'uninitialized',
		now: Math.round(new Date().getTime() / 1000),
		yesterday: this.now - (24*60*60),
		// TODO: classes_view should probably be a "preference" stored in the teacher's DB user record
		classes_view: U.local_storage_get('district_portal_classes_view', 'assignments'),	// options are 'classes' or 'assignments'

		ping_timeout: null,
		ping_timeout_time: 60 * 1000 * 10,	// every 10 minutes

		collection_title_img: {
			'English Language Arts': ela_subject_img,
			'Math': math_subject_img,
			'Science': science_subject_img,
			'Social Science': socialscience_subject_img,
			'Social and Emotional Learning': sel_subject_img,
			'Parent Resources': parent_resources_img,
			'misc': misc_subject_img,
		},

		hidden_iframe_src: '',
	}},
	computed: {
		...mapState(['user_info', 'lti_sso_status']),
		...mapGetters([]),
		role() { return this.user_info.role },
	},
	created() {
		window.vapp = this
		// update now and yesterday every X seconds
		setInterval(()=>{
			// console.log('update now')
			this.update_now()
		}, 10000)
	},
	mounted() {
		this.show_bgd_image()
		this.initialize_app({})
	},
	methods: {
		initialize_app(payload) {
			U.loading_start()
			this.$store.dispatch('initialize_app', payload).then((mode)=>{
				U.loading_stop()
				// after a tick for everything to get set up, set app_mode to show the app
				this.$nextTick(()=>{
					this.app_mode = mode
					$('body').removeClass('k-body-uninitialized')

					if (vapp.app_mode != 'login') {
						// always lti into safari right away
						this.lti_sso('safari', x=>{
							console.log('SSOd into Safari')
						})

						// start pinging to make sure we're signed in
						this.ping()
					}
				})
			})
		},

		update_now() {
			this.now = Math.round(new Date().getTime() / 1000)
			this.yesterday = this.now - (24*60*60)
		},

		has_admin_right(right) {
			// central fn for determining if the user has the given admin right
			let ar = this.user_info.admin_rights

			// su users have rights to everything
			if (ar.find(x=>x=='su')) return true

			// for collection.x.x, 'collection.level.all' gives rights to edit any collection
			if (right.indexOf('collection') > -1 && ar.find(x=>x=='collection.level.all')) return true
			// TODO: deal with other collection.level rights and collection.subject rights

			// for com.mod.x, 'com.mod.all' gives rights to moderate any community_id
			if (right.indexOf('com.mod') > -1 && ar.find(x=>x=='com.mod.all')) return true

			if (ar.find(x=>x==right)) return true

			return false
		},

		set_classes_view(which) {
			if (which != 'classes') which = 'assignments'
			U.local_storage_set('district_portal_classes_view', which)
			this.classes_view = which
			this.go_to_classes()
		},

		go_to_classes() {
			let new_route
			if (this.role == 'admin') {
				new_route = 'classes/lpindex'
			} else {
				// see above fn for how this gets set
				new_route = this.classes_view
			}

			new_route = '/' + new_route
			if (this.$route.path == new_route) return

			this.$router.push({ path: new_route })
		},

		// some utilities
		show_bgd_image(index) {
			let filenames = [
				'AllCityArts-3841-L.jpg',
				'CalmecaChineseNewYear-607-L.jpg',
				'CalmecaChineseNewYear-800-M.jpg',
				'CalmecaChineseNewYear-LOW-RES-628-L.jpg',
				'i-JM2QfQw-L.jpg',
				'JulianHighSchool-Graduation-3414-M.jpg',
				'Legacy_ES_1st_Grade-150-M.jpg',
				'Legacy_ES_1st_Grade-815-M.jpg',
				'MurrayThanksgivingLuncheon-5289-L.jpg',
				'PhillipsHighSchool-Graduation-4172-L.jpg',
				'Sullivan_HS_Grad_2020_June_17_IMG_9292-L.jpg',
			]
			// index = 0

			if (empty(index)) {
				index = U.random_int(filenames.length)
			}

			// this is another way of setting the bgd image.
			// it's easier, but with this if the window is bigger than the image, you get a gray bar on the sides or top
			// $('html').css('background', sr("url('/bgd-imgs/$1') no-repeat center center fixed", filenames[index]))

			let url = sr('/bgd-imgs/$1', filenames[index])

			// Set the new image
			$("#k-bgd-img").attr('src', url);

			// set resize event if not already set
			if (empty(this.resize_bgd_image_evt)) {
				this.resize_bgd_image_evt = $(window).on('resize', ()=>{ this.resize_bgd_image() })
			}

			// call resize_bgd_image every 100 ms for 15 seconds, to make it resizes properly after the image loads
			this.resize_bgd_image_counter = 0
			clearInterval(this.resize_bgd_image_interval)
			this.resize_bgd_image_interval = setInterval(()=>{
				this.resize_bgd_image()
				if (this.resize_bgd_image_counter > 150) clearInterval(this.resize_bgd_image_interval)
				++this.resize_bgd_image_counter
			}, 100)
		},

		resize_bgd_image() {
			let $bg = $("#k-bgd-img")
			let bg_w = $bg.width()
			let bg_h = $bg.height()
			let win_w = $(window).width()
			let win_h = $(window).height()

			// set small_screen whenever this runs
			this.$store.commit('set', ['small_screen', (win_w <= 576)])

			// console.log(sr('$1 / $2', bg_w, bg_h))

			// Determine whether width or height should be 100%; shift image left or up to compensate for difference in img/window width/height
			let left, top
			if ((win_w / win_h) < (bg_w / bg_h)) {
				left = '-' + Math.round((bg_w - win_w) / 2) + 'px'
				top = '0'
				$bg.css({height: '100%', width: 'auto', left:left, top:top});
			} else {
				left = '0'
				top = '-' + Math.round((bg_h - win_h) / 2) + 'px'
				$bg.css({width: '100%', height: 'auto', left:left, top:top});
			}
		},

		// determine if the user's session is still active; if not, show a message and call the sign out process
		ping() {
			// we want to automatically call this fn every ping_timeout_time ms. if it's manually called by something else, we reset the timeout
			clearTimeout(this.ping_timeout)

			let sign_user_out = () => {
				let msg = 'You are not signed in. You may have been automatically signed out due to inactivity.'
				this.$alert({
				    text: msg,
				}).finally(f=>{
					this.$store.dispatch('sign_out')
				})
			}

			// use plain-vanilla XMLHttpRequest to call the ping service
			var xhr = new XMLHttpRequest()
			xhr.onreadystatechange = function() {
			    if (xhr.readyState === 4) {		// fetch operation is done
					if (xhr.responseText == 'ok') {
						console.log('ping OK')
						// set the timeout to re-call this fn after ping_timeout_time
						vapp.ping_timeout = setTimeout(()=>{ vapp.ping() }, vapp.ping_timeout_time)

					} else {
						sign_user_out()
					}
				}
			}
			xhr.open('GET', '/src/ping.php')
			xhr.send()
		},

		// this fn is called from ResourceLink and ParentCenter to launch a link from a known provider by first sso'ing into the provider via safari, then opening the link
		launch_sso_resource_link(provider, url) {
			// for ixl...
			if (provider == 'ixl') {
				// if we've already sso'd to ixl, launch the link
				if (this.lti_sso_status['ixl'] == true) {
					window.open(url, '_blank')
				} else {
					this.lti_sso('ixl', error=>{
						if (error) {
							this.$alert('error: ' + error)
						} else {
							console.log('OPENING IXL URL: ' + url)
							// then if/when it works, open the url in a new window
							window.open(url, '_blank')
						}
					})
				}

			} else {
				// else if this is for one of the providers, call lti_sso to sign in via an iframe
				this.lti_sso(provider, error=>{
					if (error) {
						this.$alert('error: ' + error)
					} else {
						console.log('OPENING URL (1): ' + url)
						// then if/when it works, open the url in a new window
						window.open(url, '_blank')
					}
				})
			}
		},

		lti_sso(target, callback) {
			// for imagine and espark we can't do sso, so just do the callback
			if (target == 'imaginelearning' || target == 'espark') {
				if (callback != 'new_window') {
					callback()
				}
				return
			}

			// send a refresh sso launch if it's been > 30 min. since last sso launch
			let now = Math.floor(Date.now() / 1000)
			let last_sso_ts = (empty(this.lti_sso_status[target+'_ts'])) ? 0 : this.lti_sso_status[target+'_ts']
			if ((target == 'brainpop' || target == 'mindfulpractices' || target == 'achieve3000')) {
				if (last_sso_ts > 0) {
					console.log((now - this.lti_sso_status[target+'_ts']) + " seconds since last " + target + " sso")

					if (now - last_sso_ts > 1800) {
						console.log("refresh " + target + " sso")
						this.$store.commit('set', [this.lti_sso_status, target, false])
					}
				} else {
					console.log("initial " + target + " sso lti launch")
				}
			}

			// if we've already sso'd in and callback isn't 'new_window', just call the callback
			if (this.lti_sso_status[target] == true && callback != 'new_window') {
				if (callback) callback()
				return
			}

			// For now, for okin and streamablelearning, just do a window.open to:
			// https://okin-cps.kustomer.help/
			// https://www.streamablelearning.com/app/odlss/
			// same for now on learnaroundtheworld https://learnaroundtheworld.org/cps-odlss/events/ 
			// (i.e. skip sso for now)
			if (target == 'okin' || target == 'streamablelearning' || target == 'learnaroundtheworld') {
				callback()
				return
			}

			U.loading_start()
			let payload = {target: target}
			this.$store.dispatch('get_lti_sso_launch_form', payload).then(result=>{

				// if callback isn't 'new_window' and it's a provider we can LTI to in an iframe, do it in the iframe
				if (callback != 'new_window' && (target == 'safari' || target == 'brainpop' || target == 'mindfulpractices' || target == 'achieve3000')) {
					// create a new iframe for the sso
					// NOTE: the "sandbox" paramater prevents safari from redirecting the top window to the safari login page
					// unfortulately, though, I currently don't know how to know if the sso was successful or not
					// the "allow-popups allow-popups-to-escape-sandbox" sandbox parameters allow the initial ixl page to open the second ixl page in a new window
					$('#sso_iframe_holder').html('<iframe name="sso_iframe" sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>')

					// write out the form in the iframe; it will submit itself
					sso_iframe.document.write(result.lti_form)

					$('[name="sso_iframe"]').on('load', x=>{
						if (this.lti_sso_status[target] != true) {
							console.log(target + ' sso complete')
							this.$store.commit('set', [this.lti_sso_status, target, true])
							// some resource targets periodically get a refresh sso launch
							this.$store.commit('set', [this.lti_sso_status, target+'_ts', now])

							// delay callback for a second to let the login process finish
							setTimeout(x=>{
								U.loading_stop()
								if (callback) callback()
							}, 1500)
						}
					})

					setTimeout(x=>{
						if (this.lti_sso_status[target] != true) {
							U.loading_stop()
							console.log(target + ' sso timed out')
							this.$store.commit('set', [this.lti_sso_status, target, true])
							if (callback) callback('time_out')
						}
					}, 10000)

				} else if (target == 'ixl') {
					// show a confirm dialog including an iframe for the LTI form

					// let msg = 'To open IXL resources, first click “OPEN IXL” below to open IXL in a new tab. You should then see a number of buttons, including one labeled “Go to learning”. Click that link complete the IXL single-sign-in process. Then close the tab and click the “OPEN RESOURCE” button again to open the resource.'
					let msg = 'To open IXL resources, click the button labeled “Go to learning” in the iframe below to complete the IXL single-sign-in process. Then click “OPEN RESOURCE” to open the resource.'
					msg += '<div class="ixl_iframe_holder"><iframe name="ixl_iframe" sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe></div>'
					this.$confirm({
						title: 'SSO to IXL',
						text: msg,
						acceptText: 'Open Resource',
						dialogMaxWidth: 600
					}).then(y => {
						// set lti_sso_status to true once the user clicks to open the resource
						this.$store.commit('set', [this.lti_sso_status, target, true])
						if (callback) callback()
					}).catch(n=>{console.log(n)}).finally(f=>{})

					// then write out the LTI form in; it will submit itself
					setTimeout(x=>{
						U.loading_stop()
						ixl_iframe.document.write(result.lti_form)
					}, 100)

				} else {
					setTimeout(x=>{
						U.loading_stop()
					}, 100)

					this.sso_window = window.open()
					this.sso_window.document.write(result.lti_form)

					// set status and callback right away
					this.$store.commit('set', [this.lti_sso_status, target, true])
					if (callback && callback != 'new_window') callback()
				}

			}).catch(x=>{
				U.loading_stop()
			})
		},

		search_safari(payload) {
			// payload can currently contain keywords, grade_start, and grade_end

			// if we haven't already sso'd to safari, do so first; otherwise just open the search tab
			if (!this.lti_sso_status.safari) {
				U.loading_start()
				this.lti_sso('safari', x=>{
					U.loading_stop()
					this.open_safari_search_tab(payload)
				})
			} else {
				this.open_safari_search_tab(payload)
			}
		},

		open_safari_search_tab(payload) {
			this.$store.dispatch('get_lti_safari_search_form', payload).then(result=>{
				// for the lti_form we open a new window and write out the form, which submits itself
				// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
				let w = window.open()
				w.document.write(result.lti_form)
			})
		},
	}
}
</script>

<style lang="scss">
html {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

#k-bgd-img {
	position: fixed;
	z-index:-1;
	left: 0;
	top: 0;
}

body {
	font-size:18px;
}

.k-body-uninitialized {
	background-color:#fff;
	.spinner-wrapper {
		display:none;
	}
}

.v-application {
	font-family: $sans-serif-font;
	background-color:transparent!important;
}

.k-shadow-text {
	color:#fff;
	text-shadow: 2px 2px 8px #000;
}

.ixl_iframe_holder {
	width:500px;
	height:300px;
	margin:12px auto 0 auto;

	iframe {
		width:500px;
		height:300px;
	}
}

</style>
