<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" persistent max-width="900px"><v-card>
	<v-card-title>Edit User <b class="ml-2">{{user.email}}</b></v-card-title>
	<v-card-text style="color:#000;">
		<div class="d-flex align-center mt-3">
			<v-text-field dense outlined hide-details label="New Password" v-model="password" autocomplete="new-password"></v-text-field>
			<div style="width:280px" class="mx-3"><v-autocomplete multiple chips small-chips outlined hide-details v-model="grade_levels" @input="grade_levels_changed" :items="grade_level_options" label="Grade Level(s)" solo hide-details dense></v-autocomplete></div>
			<v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details :label="'Admin User'" v-model="is_admin" hide-details></v-checkbox>
		</div>

	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="red" dark @click="$emit('user_deleted')"><v-icon small class="mr-2">fas fa-trash</v-icon> Delete User</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="$emit('editor_cancel')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		user: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		password: '',
		grade_levels: [],
		is_admin: false,
	}},
	computed: {
		...mapState(['user_info']),
		grade_level_options() {
			let arr = [
				{value:'1', text: 'PK-2'},
				{value:'3', text: '3-5'},
				{value:'6', text: '6-8'},
				{value:'9', text: '9-12'},
			]
			return arr
		},
	},
	created() {
		// if (this.user.grade_levels.length == 0) this.grade_levels = ['1', '3', '6', '9']
		// else this.grade_levels = this.user.grade_levels.concat([])
		this.grade_levels = this.user.grade_levels.concat([])
		this.is_admin = (this.user.system_role == 'admin')
	},
	mounted() {
		// DEBUG
		vapp.user_edit = this
	},
	methods: {
		grade_levels_changed() {
			if (this.grade_levels.indexOf('0') > -1) {
				this.grade_levels = ['0']
			}
		},

		save_edits() {
			// if this is a new user, a password must be specified
			this.password = $.trim(this.password)
			if (this.user.user_id == 0 && empty(this.password)) {
				this.$alert('You must set the password for a new user.')
				return
			}

			// at least one grade level should be specified
			if (this.grade_levels.length == 0) {
				this.$alert('You must specify at least one grade level.')
				return
			}

			let payload = {
				user_id_being_edited: this.user.user_id,
				email: this.user.email,
				password: this.password,
				grade_levels: this.grade_levels,
				system_role: this.is_admin ? 'admin' : 'parent',
			}
			this.$emit('user_edited', payload)
		},
	}
}
</script>

<style lang="scss">
</style>
