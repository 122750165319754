import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 1. Define route components. These can be imported from other files
import BadRoute from './components/main/BadRoute'

import CollectionsBase from './components/collections/CollectionsBase'
import CollectionsList from './components/collections/CollectionsList'
import Collection from './components/collections/Collection'

import ParentCenter from './components/main/ParentCenter'

import AdminView from './components/admin/AdminView'
import AdminMain from './components/admin/AdminMain'
import AdminUsers from './components/admin/AdminUsers'
import AdminJumpTo from './components/admin/AdminJumpTo'
import AdminSearch from './components/admin/AdminSearch'
import ChangeLog from './components/main/ChangeLog'

// 2. Define some routes
const routes = [
	{ path: '*', component: BadRoute },
	{ path: '', redirect: '/collections' },
	{ path: '/collections_base', name: 'collections_base', component: CollectionsBase, children: [
		{ path: '/collections', name: 'collections_list', component: CollectionsList, props: true },
		{ path: '/collections/:collection_id/:unit_id', name: 'collection', component: Collection, props: true },
	] },
	{ path: '/parent_center', name: 'parent_center', component: ParentCenter, props: true },
	{ path: '/admin_base', name: 'admin', component: AdminView, children: [
		{ path: '/admin', name: 'admin_main', component: AdminMain, props: true },
		{ path: '/admin/users', name: 'admin_users', component: AdminUsers },
		{ path: '/admin/jumpto', name: 'admin_jumpto', component: AdminJumpTo },
		{ path: '/admin/search', name: 'admin_search', component: AdminSearch },
	] },

	{ path: '/changelog', name: 'changelog', component: ChangeLog },
]

// 3. Create the router instance and pass the `routes` option
const router = new VueRouter({
	// for local development, base is '/'; on the server, base is /
	base: (document.location.href.indexOf('localhost') > -1) ? '' : '/',
	mode: 'history',
	routes // short for `routes: routes`
})

// 4. export router object for inclusion in main.js
export default router
