<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>User Admin Tool</b>
			<v-spacer></v-spacer>
			<!-- <v-btn small @click="new_user_clicked" color="primary"><v-icon class="mr-2" small>fas fa-plus</v-icon> Create New User</v-btn> -->
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div v-if="all_users" style="clear:both">
				<div class="py-4 d-flex">
					<v-text-field
						v-model="search"
						prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
						label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 450px"
					></v-text-field>
					<v-btn class="ml-8" color="primary" @click="new_user_clicked"><v-icon small class="mr-2">fas fa-plus</v-icon> Create New User(s)</v-btn>
				</div>

				<v-data-table light dense
					:headers="headers"
					:items="all_users"
					:sort-by="['created_at']"
					:sort-desc="[true]"
					:must-sort="true"
					:custom-filter="table_search_filter"
					:search="search"
					hide-default-footer
					:footer-props="footer_options"
					:items-per-page="100"
					class="k-admin-users-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td><v-btn icon x-small class="mr-2" @click="edit_user(item.user_id)"><v-icon>fas fa-edit</v-icon></v-btn><span v-html="item.email"></span></td>
						<td class="text-center">{{system_role(item.system_role)}}</td>
						<td class="text-center">{{grade_levels(item.grade_levels)}}</td>
						<td class="text-center">{{item.created_at}}</td>
						<!-- <td>{{item.last_login}}</td>
						<td>{{item.login_count}}</td> -->
					</tr></template>
				</v-data-table>
			</div>
			<AdminUserEdit v-if="user_being_edited" :user="user_being_edited" @editor_cancel="user_being_edited=null" @user_edited="user_edited" @user_deleted="user_deleted"></AdminUserEdit>
		</v-card-text>
		<v-card-actions class="mt-3">
			<v-spacer></v-spacer>
			<v-btn color="secondary" @click="$emit('cancel_editor')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Done</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminUserEdit from './AdminUserEdit'

export default {
	components: { AdminUserEdit },
	props: {
	},
	data() { return {
		dialog_open: true,
		all_users: null,
		headers: [
			{ text: 'User', align: 'left', sortable: true, value:'user' },
			{ text: 'Rights', align: 'center', sortable: true, value:'system_role' },
			{ text: 'Grade Level(s)', align: 'center', sortable: true, value:'grade_levels' },
			{ text: 'Date Created', align: 'center', sortable: true, value:'created_at' },
			// { text: 'Last Login', align: 'center', sortable: true, value:'last_login' },
			// { text: 'Login Count', align: 'center', sortable: true, value:'login_count' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		user_being_edited: null,
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
		// DEBUG
		vapp.all_users_component = this
		this.load_admin_user_list()
	},
	mounted() {
	},
	methods: {
		load_admin_user_list() {
			let payload = {
				user_id: this.user_info.user_id,
			}
			U.loading_start()
			U.ajax('admin_get_all_users', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				this.all_users = result.all_users
				// format for each admin_user: { user_id, system_role, grade_levels, email, created_at, last_login, login_count }
			});
		},

		system_role(system_role) {
			if (system_role == 'admin') return 'System Admin'
			else return 'Parent'
		},

		grade_levels(grade_levels) {
			if (grade_levels.length == 0) return 'ALL'
			let arr = []
			if (grade_levels.indexOf('1') > -1) arr.push('PK-2')
			if (grade_levels.indexOf('3') > -1) arr.push('3-5')
			if (grade_levels.indexOf('6') > -1) arr.push('6-8')
			if (grade_levels.indexOf('9') > -1) arr.push('9-12')
			return arr.join(', ')
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check email
			if (item.email.toLowerCase().search(re) > -1) return true

			// check rights
			if (this.system_role(item.system_role).toLowerCase().search(re) > -1) return true

			// if we get to here return false
			return false
		},

		edit_user(user_id) {
			this.user_being_edited = this.all_users.find(x=>x.user_id==user_id)
		},

		user_edited(payload) {
			payload.user_id = this.user_info.user_id
			console.log(payload)

			U.loading_start()
			U.ajax('admin_save_user', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				let msg
				if (this.user_being_edited.user_id == 0) msg = 'New user created.'
				else msg = 'User updated.'

				// result.saved_user_id will contain the new user_id if this is a new user
				this.user_being_edited.user_id = result.saved_user_id
				this.user_being_edited.grade_levels = payload.grade_levels
				this.user_being_edited.system_role = payload.system_role

				// close user editor
				this.user_being_edited = null

				this.$alert(msg)
			});
		},

		user_deleted() {
			// if user_id was 0, it was a new user, so just delete
			if (this.user_being_edited.user_id == 0) {
				this.all_users.splice(this.all_users.length-1, 1)
				this.user_being_edited = null
				return
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete user <b>$1</b>?', this.user_being_edited.email),
			    acceptText: 'Delete User',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				let payload = {
					user_id: this.user_info.user_id,
					user_id_to_delete: this.user_being_edited.user_id,
				}
				U.loading_start()
				U.ajax('admin_delete_user', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in admin ajax call: ' + result.status); vapp.ping(); return;
					}

					let index = this.all_users.findIndex(x=>x==this.user_being_edited)
					this.all_users.splice(index, 1)

					// close user editor
					this.user_being_edited = null
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		new_user_clicked(iv) {
			if (typeof(iv) != 'string') iv = ''

			this.$prompt({
				title: 'Add New User(s)',
				text: 'Enter user email adress(es) (separate multiple emails with commas or line breaks):',
				promptType: 'textarea',
				initialValue: iv,
				dialogMaxWidth: 800,
				acceptText: 'Add User',
			}).then(text => {
				text = $.trim(text).toLowerCase()

				let emails = text.split(/\s*[,\n]\s*/)
				if (emails.length > 50) {
					this.$alert('A maximum of 50 users may be created each batch.')
					return
				}
				for (let email of emails) {
					if (email.indexOf('@') == -1) {
						this.$alert(sr('Entry “$1” is not a valid email.', email)).then(x=>{this.new_user_clicked(text)})
						return
					}
					for (let identifier in this.all_users) {
						if (this.all_users[identifier].email == email) {
							this.search = email
							this.$alert(sr('A user with email “$1” is already in the system.', email))
							return
						}
					}
				}

				console.log(emails)

				this.$confirm({
				    title: 'Confirm',
				    text: sr('This will create $1 new account(s). Proceed?', emails.length),
				    acceptText: 'Create Account(s)',
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					this.new_users_create(emails)
				}).catch(n=>{console.log(n)}).finally(f=>{})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		new_users_create(emails) {
			let arr = []
			let data = ''
			for (let email of emails) {
				// generate a random password for each one (it's easier for me to do this client-side than server-side, and we need to report the passwords to the admin user)
				// constraints (for Safari compatibility): 8 chars or longer; at least one of each: lowercase, uppercase, number, special char (# is OK)
				// so start with 5 lowercase letters, 1 uppercase letter, a digit, and a '#' character, then randomize the order
				// pick from these 19 letters, leaving out letters like O and I that can be ambiguous
				let letters = U.shuffle_array('ABCEFHJKLMNPRSTWXYZ'.split(''))
				let password = ['#', letters[0]];
				for (let i = 1; i < 6; ++i) password.push(letters[i].toLowerCase())
				password.push(U.shuffle_array('123456789'.split(''))[0])
				password = U.shuffle_array(password).join('')

				arr.push({
					user_id_being_edited: 0,
					system_role: 'parent',
					grade_levels: [1,3,6,9],
					email: email,
					password: password,
					created_at: 'TODAY',
					last_login: '-',
					login_count: 0,
				})

				data += sr('$1\t$2\n', email, password)
			}
			U.copy_to_clipboard(data)

			let payload = {
				user_id: this.user_info.user_id,
				users: arr,
			}

			U.ajax('admin_save_users', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				let msg = '<table class="k-admin-new-users-table"><tr><th>Email</th><th>Password</th></tr>'

				for (let i = 0; i < result.users.length; ++i) {
					let user = result.users[i]
					this.all_users.push(user)
					msg += sr('<tr><td>$1</td><td>$2</td></tr>', user.email, arr[i].password)
				}

				msg += '<table>'

				this.$alert({
					title: 'New User(s) created and copied to clipboard',
					text: msg,
					dialogMaxWidth: 600,
				})
			})
		},

		// no longer used...
		new_user_create(email) {
			this.all_users.push({
				user_id:0,
				system_role: 'parent',
				grade_levels: [],
				email: email,
				created_at: 'TODAY',
				last_login: '-',
				login_count: 0,
			})
			this.user_being_edited = this.all_users[this.all_users.length-1]
		},
	}
}
</script>

<style lang="scss">
.k-admin-new-users-table {
	border-collapse: collapse;
	margin:0 auto;
	th, td {
		font-size:12px;
		padding:2px 6px;
		border:1px solid #ccc;
	}
}
</style>
